import React, { Component } from 'react'
//import { generatePDFContent } from '../../Services/pdfService';

export default class TcTest extends Component {
    generatePDF = () => {
        const { bookingData } = this.state;
        //generatePDFContent(bookingData);
    };
  render() {
    return (
      <div>
        <div className="container">
        <div>
                <button onClick={this.generatePDF}>Generate PDF</button>
            </div>
        </div>
      </div>
    )
  }
}
