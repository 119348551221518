import React, { Component } from 'react'
import { useNavigate, Link, json } from "react-router-dom";
class TermsConditions extends Component {
  render() {
    return (
      <div>
       <div className="container" style={{ paddingTop: '25px' }}>
        <h2>Travel Mythri – Terms & Conditions</h2>
        <div className="privacy-inner">
               <h5><strong>Applicability</strong></h5>  
               <p>
               This agreement includes the terms and conditions governing the provision of services by Travel Mythri and its affiliates to users intending to book any product or service offered by the company, whether through its websites, mobile applications or any other means such as salespersons, offices, call centers, advertisements and information campaigns.
                </p>       

 <h5><strong>User’s agreement to terms and conditions</strong></h5>  
               <p>
               The Terms and Conditions is a legal contract between you being, an individual customer, user or beneficiary of this service and Travel Mythri (referred to as ‘‘TM/Travel Mythri/'travelmythri.com"), Room No: 63/2271, First Floor, Alhind Tower, Jaffer Khan Colony Road, Kozhikode, Kerala State 673006, India. 
                </p> 

<p>
TM reserves the right to add, modify or remove any aspect of these Terms and Conditions without prior notice. Any alterations to these terms, as well as any terms posted on this portal, become effective immediately upon posting. It is your responsibility to periodically review the "Terms & Conditions" link to stay informed about any updates the portal may implement.
</p>

<p>
Upon accessing or utilizing this site or any of its services, you agree to the terms and conditions outlined below, inclusive of any additional guidelines and future updates. The rights and obligations of both the User and TM concerning any services provided by TM are constrained by the scope of this agreement. TM retains the authority to terminate access to any of its services at any time and for any reason, without prior notice. Certain products or services are supplied by third-party providers and in addition to this agreement, the user must acknowledge and adhere to the terms and conditions of such providers, as provided and updated by TM, which are deemed integral to this agreement. Users must review and accept the relevant terms and conditions for the service or product they are availing. TM's services are extended to users under the condition of acceptance without alteration. It is emphasized that by utilizing TM's services, the user is deemed to have accepted this agreement and its terms and conditions. Should the user disagree with any part of it, they should refrain from utilizing TM's services.
</p>

       <p>
       By utilizing TM's website and mobile applications, Users authorize TM and its agents to access designated third-party sites, including banks and other payment gateways, for retrieving requested details. Users are responsible for maintaining the privacy of their account and are fully liable for any activities conducted using their password or account. In the event of unauthorized account usage or security breaches, it is the user's responsibility to promptly notify TM in writing. TM disclaims liability for any losses incurred due to unauthorized account usage. Internet transmissions are inherently not completely private or secure. Sending a message to TM does not impose any special responsibility on TM to you. 
        </p>         
<p>
You acknowledge that TM acts merely as an agent on behalf of the service providers offering their services on the TM platform and that TM solely provides intermediary services to facilitate services for you. TM is not the final service provider to you and therefore TM shall not be held responsible for, nor have any liability for, any deficiency, interruption, alteration, rescheduling, delay or cancellation of services provided by any service provider or entity that you may engage from the content available on the portal. 
</p>
<p>
To access services on the TM portal, TM reserves the right to impose certain fees such as convenience fees or service fees or transaction fees, in addition to the booking cost charged by the service providers. TM also reserves the right to modify any and all fees periodically. Any additional fees, including modifications thereof, will be disclosed to the customer before confirming the booking or collecting payment. In the event of undercharging of the booking amount, taxes, statutory fees, convenience fees, service fees etc., due to technical errors or other reasons, TM reserves the right to deduct, charge, or claim the balance amount from the customer and the customer shall be liable to pay such balance amount to TM. If the undercharged amount is not paid before the utilization date, TM may cancel such bookings.
</p>
<h5><strong>Access to the Portal</strong></h5>
<p>
The portal is exclusively for personal use and you are prohibited from copying, distributing, modifying, publishing, transferring or selling any of its creative properties. TM provides you with a non-exclusive, non-transferable, limited right to access, view and use and transact on this portal. You agree not to disrupt or attempt to disrupt the operation of this portal in any manner. You represent and warrant that all information you supply to us, about yourself and others, is true and accurate. You acknowledge that, apart from information, products or services explicitly identified as provided by TM, we do not manage, oversee or endorse any information, products or services on the Internet in any manner. Additionally, you understand that TM cannot guarantee or warrant that files available for download through the portal will be free of viruses or other potentially damaging code. It is your responsibility to implement procedures to meet your specific needs and to ensure the accuracy of data input and output. TM reserves the right to add, modify, discontinue, remove or suspend any other content or services posted on this portal, including features and specifications of products described or depicted on the portal, temporarily or permanently, at any time, without prior notice and without incurring liability.
</p>
<h5><strong>Right to cancel and Right to refuse the services</strong></h5>
<p>
The user agrees to provide TM with accurate and valid information while using the portal under this user agreement and terms and not to misrepresent any facts. Failure to do so may result in the user being unable to avail services from TM. If TM suspects unauthorized requests or incorrect information provided by the User, TM reserves the right to cancel the bookings, without prior notice.
</p>
<p>TM reserves the right to refuse any booking at its sole discretion without providing a reason. Services or confirmed booking details will not be provided until full payment is received from the user. Additionally, TM may take various actions, including limiting user activity or refusing access to the portal, if the user breaches the user agreement and terms, provides unverifiable information or infringes on third-party rights or laws. Users must refrain from sending abusive, threatening or offensive content to TM, violating intellectual property rights or engaging in spam. Violation of these terms may result in legal action by TM.</p>

<p>
Furthermore, users acknowledge that end service providers may refuse to provide services for bookings made through TM due to various reasons, including behavioral issues, public safety concerns, health hazards or government orders. In such cases, any liability or claims against refusal rest solely with the end service provider, not TM.
</p>
<h5><strong>Cancellation & Refund</strong></h5>
<p>
The airline tickets obtainable via the portal are subject to the terms and conditions of the respective airline, including but not restricted to cancellation and reimbursement policies.
</p>
<p>
TM merely serves as a coordinator to allow the user to reserve a flight ticket. The contract of service for the use of the flight always resides between the user and the respective airline.
</p>

<p>
Airlines maintain the authority to adjust flight timings, routes, alter or cancel flights or itineraries independently and without prior notice to TM. As a service facilitator, TM lacks control or authority over the operations of the airlines and thus bears no responsibility for any loss, direct or incidental, that a user may suffer due to such alterations or flight cancellations. The user is obliged to pay applicable fees in the event of any adjustments or modifications to an existing booking. Nevertheless, charges for changes or modifications to existing bookings may vary depending on the airline's policy and fare class. Each booking made on TM is subject to cancellation fees imposed by the airline, which may differ based on the flight and booking class.
</p>
<p>
TM imposes a fee of Rs.150 per passenger/per sector for domestic flights and Rs.300 per passenger/per sector for international air tickets as cancellation service charges. Certain bookings may be non-refundable in accordance with the specific airline's policy, while others may not be subject to partial cancellation as per the specific airline's policy. 
</p>
<p>
Cancellation requests can be submitted by email to TM. We aid in making various alterations to bookings. In some instances, it may be necessary to directly contact the airline. 
</p>
<p>Amendment fees on TM are subject to change in accordance with the airline's policy, which varies based on flight timings and booking class. To cancel tickets within 48 hours of the journey, TM advises customers to directly contact the Airlines and subsequently submit the cancellation request to our portal for refunds. In the case of direct cancellations through airlines, refunds will be issued according to airline charges along with our aforementioned service charges. After flight departure and in the event of a No Show, we do not entertain any cancellation requests. TM will refund the same amount to the customer that we receive from airlines, if any, in the case of a No-Show.</p>
<p>The User bears full responsibility for all charges or fees incurred through the use of the services. In the event that a reservation fails to confirm for any reason, a refund will be initiated. We are not obligated to arrange alternative bookings to compensate for or replace the unconfirmed reservation. Any subsequent bookings will be treated as new transactions, with no connection to previous unconfirmed reservations. Any associated refunds will be processed according to the policies of the service providers (Airlines, Hotels, etc.). TM will process refunds upon receipt from the service providers, with the exception of the non-refundable convenience fee and cancellation service charges where applicable. The User acknowledges that TM is not liable for any delay in refund or the absence of a refund from the respective service provider.</p>
<p>
Any cancellations made directly with the airline must be communicated to TM to initiate the refund procedure. Refund processing times may vary based on payment method and banking procedures. TM will deduct its service fee, separate from the convenience fee, before processing refunds.
</p>
<p>Refunds will be returned to the original payment account. For instance, if payment was made via credit card, TM will reverse the charge to the same card; similarly, for debit card payments, TM will credit the same account.</p>
<p>For partially used tickets, discounts applied during booking will be subtracted from the refund amount.</p>
<p>Refund requests made after 60 days will not be entertained and the amount will be forfeited.</p>





<h5><strong>Mandatory travel requirements</strong></h5>
<p>
It is imperative to carry a valid visa or any mandatory travel document for the country you are visiting or transiting through and it is highly recommended to verify the visa requirements with the respective airline and embassy and adhere to them. TM bears no responsibility for issues arising from visa requirements, including travel restrictions and is not obligated to refund any amount to the user if they are unable to utilize the booking due to visa denial.
</p>
<h5><strong>Jurisdiction</strong></h5>
<p>TM submits to the jurisdiction of the courts in Kozhikode.</p>
  <h5><strong>Limited Liability</strong></h5>                              
  <p>By booking services, users acknowledge that TM serves as an intermediary platform and the ultimate contractual agreement lies between the user and the service provider. Users also understand that information displayed on the website is provided by the service provider and TM cannot be held liable for inaccuracies or violations of laws or regulations. TM disclaims responsibility for any oversight or misunderstanding of the content on its pages. Users explicitly agree that their use of the services is solely at their own risk.</p>
  <h5><strong>Privacy Policy</strong></h5> 
  <p>
  TM is committed to safeguarding your privacy. For a comprehensive understanding of our practices, please review our current Privacy Policy, which also regulates your use of the Website: Privacy policy  – <Link to="https://www.travelmythri.com/Privacy_Policy">https://www.travelmythri.com/Privacy_Policy</Link>
  </p>
  <p>By accessing the portal, the user acknowledges and agrees to abide by the provisions outlined in the Privacy Policy and thereby grants consent for TM to utilize the user's personal data in conformity with the stipulations of the Privacy Policy.</p>
  <h5><strong>Indemnity</strong></h5>
  <p>The user agrees to indemnify, defend and hold harmless TM, its affiliates and their officers, successors and assigns from any losses, liabilities, claims, damages, costs and expenses arising from any breach of representation or warranty or non-performance of covenant by the user. The User is solely responsible for any breach of country-specific rules, regulations, or general code of conduct and TM cannot be held liable for such breaches.</p>
 <h5><strong>Contact Us</strong></h5>
 <p>We believe in addressing the concerns brought forward by the users. If you have any questions, concerns or complaints, please reach out to us at customercare@travelmythri.com  
 </p>
 
 
  </div>
      </div>
      
      </div>
    )
  }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <TermsConditions {...props} navigate={navigate} />;
  }
  
  export default WithNavigate;
