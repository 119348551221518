import React from 'react'
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function ErrorPage() {
  let navigate = useNavigate();
  useEffect(() => {
   
    navigate('/');
 });
  
  return (
    <div>
  <div>
  <div className="lock">
    <div className="message">
      <h1>Access to this page is restricted</h1>
      <Link to="/"> Goto Home</Link>
      {/* <p>Please check with the site admin if you believe this is a mistake.</p> */}
    </div>
  </div>
</div>

    </div>
  )
}

export default ErrorPage
