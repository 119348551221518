import React, { Component } from 'react'
import { GetInsuranceHistory } from '../../Services/AirlineService'
import { Link, useNavigate } from 'react-router-dom';
import { CheckNetworkStatus } from '../../Utilities/Validations';
import { GetTicketCopyUrl } from "../../Services/FlightDetails"
import { InsuranceTicket } from "../../Services/AirlineService"
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class Insurance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInsModal: false,
            isLoaded: false,
            RequestBody: {},
            ResponseBody: [],
            HistoryList: [],
            InsurancePaxlistRsp: [],
            InsuranceLogDetails: [],
            cancel: false,
            showInsurModal: false
        };
        this.InsuranceList = this.InsuranceList.bind(this);
    }
    componentDidMount() {
        this.setState({

            isLoaded: false,
        });
        this.InsuranceList();
    }

    handleInsShow = () => {
        this.setState({ showInsModal: true });
    };

    handleInsClose = () => {
        this.setState({ showInsModal: false });
    };

    handleInsurShow = (item) => {
        this.setState({ showInsurModal: true });

        this.setState({ InsurancePaxlistRsp: item.objInsurancePax });
    };

    handleInsurClose = () => {
        this.setState({ showInsurModal: false });
    };




    InsuranceList() {

        //Check network connection...
        if (!CheckNetworkStatus())
            return;

        GetInsuranceHistory()
            .then(response => {

                if (response.data.status) {
                    let getData = {};
                    getData = response.data;
                    this.setState({
                        ResponseBody: getData,
                        HistoryList: getData.objInsuranceHistory,
                        isLoaded: true,

                    });
                }
                else {
                    this.setState({
                        ResponseBody: {},
                        HistoryList: [],
                        isLoaded: true,
                    });
                }
            })
            .catch((error) => {
                if(error.response.data.responseMessage=="InvalidToken" || error.response.data.responseMessage == "TokenTimeOut")
                {
               
    
                localStorage.removeItem("TM_UDetails");
                localStorage.removeItem("tokenValue");
                localStorage.removeItem("onewaySearchResult");
                localStorage.removeItem("NonUsertokenValue");
                localStorage.removeItem("DeviceId");
                localStorage.removeItem("RS");
    
                toast.success('Something went wrong...', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                  });
                  
                  this.props.navigate('/');
                  
                }
              });
    }

    handleTicketClick = (id) => {

        let data = {};
        data.bookLogId = id;

        InsuranceTicket(data).then((response) => {
            if (response.data.status) {

                let getData = {};
                getData = response.data;
                this.setState({
                    InsuranceLogDetails: getData.passengers,
                });
                this.handleInsShow();
            }
            else {
                toast.success('Something Went Wrong...', {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined, // or any other configuration you want
                    theme: 'colored'
                });
            }
        })
            .catch((error) => {
                console.error('Error applying promo code:', error);
                if (error.response.data.responseMessage == "InvalidToken") {

                    localStorage.removeItem("TM_UDetails");
                    localStorage.removeItem("tokenValue");
                    localStorage.removeItem("onewaySearchResult");

                    this.props.navigate('/');

                }




            });
    }


    render() {
        return (
            <div className="my-acnt-bg">
                <div className="container">
                    <div className="user-profile pt-3">
                        <h5 className="text-white">My Account</h5>
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <Link to="/Profile"><button className="nav-link " id="nav-home-tab" type="button" >Profile</button></Link>
                                <Link to="/MyTrips"><button className="nav-link" id="nav-trips-tab" type="button" >My Trips</button></Link>
                                <Link to="/Insurance"><button className="nav-link active" id="nav-trips-tab" type="button" >Insurance</button></Link>
                                <Link to="/AccountStatement"><button className="nav-link" id="nav-account-tab" type="button">My Transactions</button></Link>

                            </div>
                        </nav>
                        <div className="tab-content pt-3" id="nav-tabContent">

                            <div>
                                <div className="profile-area mb-3">
                                    <h4>Insurance</h4>
                                    <div className="my-trips-area">
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">



                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                {this.state.isLoaded && this.state.HistoryList.length > 0 && this.state.HistoryList.map((item) => (
                                                    <ul className="trip-list" key={item.alhindPnr}>
                                                        <li className="trip-area mb-3">
                                                            <span className="ins-head d-flex flex-row justify-content-between b-bottom">
                                                                <h4 className="no-margin">PNR : {item.alhindPnr}</h4>
                                                                <h4 className="no-margin">Total Amount : {item.totalAmount}</h4>
                                                            </span>
                                                            

                                                            <span className="ins-head d-flex flex-row pt-3 pb-3 justify-content-between">

                                                                <h5 className="mr-15"><span style={{ fontWeight: '400' }}>Plan Name:</span> &nbsp;{item.planName}</h5>
                                                                <h5 className="mr-15"><span style={{ fontWeight: '400' }}>Date:</span> &nbsp;{new Date(item.bookingDate).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</h5>
                                                                <h5 className="mr-15"><span style={{ fontWeight: '400' }}>Type:</span> &nbsp;{item.bookingType}
                                                                </h5>
                                                                <button className="ins-viewbtn"
                                                                onClick={() => this.handleInsurShow(item)} // Modified: Use an anonymous function
                                                            >
                                                                 View Details <img src="assets/img/eye.svg" width={10} />
                                                            </button>
                                                            </span>
                                                           
                                                          
                                                                   
                                                                        <a href={item.policyDocument} target="_blank" className="btn btn-primary">View document</a>

                                                                  
                                                             

                                                        </li>


                                                    </ul>


                                                ))}

                                                <Modal show={this.state.showInsurModal} onHide={this.handleInsurClose} backdrop="static" size="xl" centered>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Insurance Details</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Policy Number</th>
                                                                    <th>Passenger Name</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.InsurancePaxlistRsp.map((item1, index1) => (
                                                                    item1 && (
                                                                        <tr key={index1}>
                                                                            <td>{item1.policyNumber}</td>
                                                                            <td>{item1.paxName}</td>
                                                                            <td>{item1.status}</td>
                                                                            {/* <td><Button variant='primary' className="viewBtn">View</Button></td> */}
                                                                        </tr>
                                                                    )
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </Modal.Body>
                                                </Modal>



                                                {/* New Insurance design starts */}




                                                {this.state.isLoaded && this.state.HistoryList.length == 0 && <div className="d-flex flex-row align-items-center justify-content-center">
                                                    <div className="mr-15">
                                                        <img src="assets/img/suitcase.svg" width={100} />
                                                    </div>
                                                    <div>
                                                        <h4>Looks empty, you've no bookings.</h4>
                                                        <p>When you book a trip, you will see your itinerary here.</p>
                                                    </div>
                                                </div>}
                                                {!this.state.isLoaded && <div className="text-center">
                                                    <span className="loader"></span>
                                                </div>}
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <Insurance {...props} navigate={navigate} />
}

export default WithNavigate
