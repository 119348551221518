import React, { Component } from 'react'
import { useNavigate, Link, json } from "react-router-dom";
class ComingSoon extends Component {
  render() {
    return (
      <div>
        <div className="text-center mb-3">
        <h2 className="mb-2 mx-2 cs-title mt-3">Launching soon!</h2>
        <p className="cs-text">We're creating something awesome! Stay tuned.</p>
        <img src="assets/img/coming-soon.png" width={400} />
        </div>
      
      </div>
    )
  }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <ComingSoon {...props} navigate={navigate} />;
  }
  
  export default WithNavigate;
