import React, { Component } from 'react'
import dateFormat, { masks } from "dateformat";
import DatePicker from "react-datepicker";
import { AES, enc } from 'crypto-js';
import { useNavigate, Link, json, useLocation } from 'react-router-dom'
import { GetAirportList } from '../../Services/UtilitiesServices';
import { CheckNetworkStatus } from '../../Utilities/Validations';
import { GetOneWaySearch } from '../../Services/OneWaySearch';
import { toast } from 'react-toastify';

class ModifySearchPopUp extends Component {

  allAirportGlobal = [];
  constructor(props) {
    super(props);
    this.targetTextBoxRef = React.createRef();
    this.datePickerRef = React.createRef();
    this.targetTextBoxRef2 = React.createRef();
    this.datePickerRef2 = React.createRef();
    this.autoSuggestResultRef = React.createRef();
    this.autoSuggestResultRef2 = React.createRef();
    this.autoSuggestResultRef3 = React.createRef();
    this.autoSuggestResultRef4 = React.createRef();
    this.endDatePickerRef = React.createRef();
    this.state = {
      adult: 1,
      child: 0,
      infant: 0,
      AirportJson: [],
      selectedIndex: -1,
      selectedIndex2: -1,
      selectedIndex3: -1,
      selectedIndex4: -1,
      originError: false,
      destinationError: false,
      originErrorR: false,
      destinationErrorR: false,
      RequestFields: [],
      selectedFareId: null,
      selectedNetAmount: null,
      selectedAmount: null,
      OnewayFullSearchResult: [],
      LowerFareResult: [],
      //ItinSearchResult: [],

      AvlAirlineResult: [],
      RequestBody: {},
      isLoaded: false,
      isResultFound: false,
      isFadeOut: true,
      FlightDetailsResult: [],
      objitinResult: [],
      objseglistResult: [],
      itinId: 0,
      fareId: 0,
      providerCode: "",
      itinIdR: 0,
      fareIdR: 0,
      providerCodeR: "",
      originSectorRound: {},
      destinationSectorRound: {},

      originSectorOneway: [],

      RoundTripFullSearchResult: [],
      LowerFareResult: [],
      ItinSearchResult: [],
      AvlAirlineResult: [],
      ItinSearchResultR: [],

      ResponseBodyCopy: {},
      onewayDepartureDate: new Date(),

      //Filter
      ResultFIlter: {
        steps: []
      },
      Airlines: [],
      ItinSearchResultCopy: [],
      priceRange: 0,
      DepartureTimes: [],
      ArrivalTimes: [],
      Stops: [],


      //Autocomplete...
      value: '',
      suggestions: [],
      OnewayOriginSearchTerm: '',
      OnewayDestinationSearchTerm: '',
      OnewayOriginSearchResults: [],
      OnewayDestinationSearchResults: [],
      OnewayOriginAirport: [],
      OnewayDestinationAirport: [],

      //RoundTrip
      RoundTripOriginSearchTerm: '',
      RoundTripDestinationSearchTerm: '',
      RoundTripOriginSearchResults: [],
      RoundTripDestinationSearchResults: [],
      RoundTripOriginAirport: [],
      RoundTripDestinationAirport: [],


      RoundTripOnwardDate: new Date(),
      RoundTripReturnDate: new Date(),
      Today: new Date(),

      MoreFareDefaultSelectionIndex: 0,
      showDropdown: false,
      showRoundDropdown: false,
    };
    this.swapOriginAndDestination = this.swapOriginAndDestination.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleRoundDropdown = this.toggleRoundDropdown.bind(this);
    this.handleRoundClickOutside = this.handleRoundClickOutside.bind(this);
    this.closeOneDropdown = this.closeOneDropdown.bind(this);
    this.closeRoundDropdown = this.closeRoundDropdown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('mousedown', this.handleRoundClickOutside);
    //Get All Airports...
    this.getAllAirports();


    let searchRequest, modifySearchPopUpTab = 0;
    const params = new URLSearchParams(window.location.search);
    if (window.location.pathname == "/Oneway") {
      searchRequest = params.get('onewaySearchRequest');
      modifySearchPopUpTab = 1;
    }
    else if (window.location.pathname == "/Roundtrip") {
      searchRequest = params.get('IRTSearchRequest');
      modifySearchPopUpTab = 2;
    }
    else if (window.location.pathname == "/InternationalRoundTrip") {
      searchRequest = params.get('CRTSearchRequest');
      modifySearchPopUpTab = 2;
    }

    this.requestBody = JSON.parse(searchRequest);

    this.setState({
      adult: this.requestBody.adult,
      child: this.requestBody.child,
      infant: this.requestBody.infant,
    })

    this.setState({
      RequestBody: this.requestBody,
      modifySearchPopUpTab: modifySearchPopUpTab,
    })
    if (window.location.pathname == "/Oneway")
      this.setAirportDetailsFromOnewayRequestBody();
    else
      this.setAirportDetailsFromRequestBody();

    if (modifySearchPopUpTab != 0) {
      this.setState({
        isLoaded: true,
      })
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('mousedown', this.handleRoundClickOutside);
  }
  setAirportDetailsFromRequestBody() {
    const params = new URLSearchParams(window.location.search);
    const CRTSearchRequest = params.get('CRTSearchRequest');

    let dataOnward = {}, dataReturn = {};
    //let requestDummyOnward=JSON.parse(localStorage.getItem("onewaySearchResult")).objsectorlist[0];
    let requestDummyOnward = this.requestBody.objSectorList[0];
    //let requestDummyReturn=JSON.parse(localStorage.getItem("onewaySearchResult")).objsectorlist[1];
    let requestDummyReturn = this.requestBody.objSectorList[1];

    ///////////////////////////////////////////////////////////////////////////////////////////
    let TravelDateDep = dateFormat(requestDummyOnward.departureDate, "yyyy-mm-dd");;
    let TravelDateRet = dateFormat(requestDummyReturn.departureDate, "yyyy-mm-dd");;
    let OnewayFullSearchResult = this.state.OnewayFullSearchResult;
    OnewayFullSearchResult.departureDate = TravelDateDep;

    //Data...
    let RoundTripOnwardDate = TravelDateDep;
    let RoundTripReturnDate = TravelDateRet;

    //View..
    let RoundTripFullSearchResult = this.state.RoundTripFullSearchResult;
    RoundTripFullSearchResult.departureDate = TravelDateDep;
    RoundTripFullSearchResult.returnDate = TravelDateRet;
    ///////////////////////////////////////////////////////////////////////////////////////////

    this.OnewayOrigin = requestDummyOnward.origin;
    this.RoundTripOrigin = requestDummyOnward.origin;
    dataOnward.code = requestDummyOnward.origin;
    dataOnward.countryCode = requestDummyOnward.origincountry;

    this.OnewayDestination = requestDummyOnward.destination;
    this.RoundTripDestination = requestDummyOnward.destination;
    dataReturn.code = requestDummyReturn.origin;
    dataReturn.countryCode = requestDummyReturn.origincountry;


    let date = {};
    date.departureDate = dateFormat(requestDummyOnward.departureDate, "yyyy-mm-dd")

    this.setState({
      OnewayOriginAirport: dataOnward,
      OnewayDestinationAirport: dataReturn,
      OnewayFullSearchResult: OnewayFullSearchResult,

      RoundTripOriginAirport: dataOnward,
      RoundTripDestinationAirport: dataReturn,
      originSectorRound: date,

      RoundTripOnwardDate: RoundTripOnwardDate,
      RoundTripReturnDate: RoundTripReturnDate,
      RoundTripFullSearchResult: RoundTripFullSearchResult,
    })
  }

  setAirportDetailsFromOnewayRequestBody() {
    let dataOnward = {}, dataReturn = {};
    const params = new URLSearchParams(window.location.search);
    const data = params.get('onewaySearchRequest');
    let requestDummyOnward = JSON.parse(data).objSectorList[0];
    //let requestDummyReturn=JSON.parse(localStorage.getItem("onewaySearchResult")).objsectorlist[1];

    this.OnewayOrigin = requestDummyOnward.origin;
    this.RoundTripOrigin = requestDummyOnward.origin;
    dataOnward.code = requestDummyOnward.origin;
    dataOnward.countryCode = requestDummyOnward.origincountry;

    this.OnewayDestination = requestDummyOnward.destination;
    this.RoundTripDestination = requestDummyOnward.destination;
    dataReturn.code = requestDummyOnward.destination;
    dataReturn.countryCode = requestDummyOnward.destinationcountry;

    ////////////////////////////////////////////////////////////////////////////////
    // Set Date for Round Trip Onward And Return...
    let TravelDate = dateFormat(requestDummyOnward.departureDate, "yyyy-mm-dd");;
    //View...
    let RoundTripFullSearchResult = this.state.RoundTripFullSearchResult;
    RoundTripFullSearchResult.departureDate = TravelDate;
    RoundTripFullSearchResult.returnDate = TravelDate;

    //Data...
    let RoundTripOnwardDate = TravelDate;
    let RoundTripReturnDate = TravelDate;
    ////////////////////////////////////////////////////////////////////////////////   

    let date = {};
    date.departureDate = dateFormat(requestDummyOnward.departureDate, "yyyy-mm-dd")
    let OnewayFullSearchResult = this.state.OnewayFullSearchResult;
    OnewayFullSearchResult.departureDate = date.departureDate;
    this.setState({
      OnewayOriginAirport: dataOnward,
      OnewayDestinationAirport: dataReturn,

      RoundTripOriginAirport: dataOnward,
      RoundTripDestinationAirport: dataReturn,
      originSectorRound: date,
      OnewayFullSearchResult: OnewayFullSearchResult,
      RoundTripFullSearchResult: RoundTripFullSearchResult,

      RoundTripOnwardDate: RoundTripOnwardDate,
      RoundTripReturnDate: RoundTripReturnDate,
    })
  }

  swapOriginAndDestination() {
    let tempData;
    tempData = this.state.OnewayOriginAirport;
    let origin = this.state.OnewayOriginSearchTerm;
    let tempOnewayOriginError = this.state.originError;
    let tempRoundOriginError = this.state.originErrorR;

    this.setState({
      OnewayOriginAirport: this.state.OnewayDestinationAirport,
      OnewayDestinationAirport: tempData,

      OnewayOriginSearchTerm: this.state.OnewayDestinationSearchTerm,
      OnewayDestinationSearchTerm: origin,

      originError: this.state.destinationError,
      destinationError: tempOnewayOriginError,
    })

    //Round
    let tempData1;
    tempData1 = this.state.RoundTripOriginAirport;
    let origin1 = this.state.RoundTripOriginSearchTerm;

    this.setState({
      RoundTripOriginAirport: this.state.RoundTripDestinationAirport,
      RoundTripDestinationAirport: tempData1,

      RoundTripOriginSearchTerm: this.state.RoundTripDestinationSearchTerm,
      RoundTripDestinationSearchTerm: origin1,

      originErrorR: this.state.destinationErrorR,
      destinationErrorR: tempRoundOriginError,
    })

    //Same airport check by Global variable "this.RoundTripOrigin, this.RoundTripDestination" and "this.OnewayDestination, this.OnewayOrigin"...
    let tempOnewayOrigin = this.OnewayOrigin;
    this.OnewayOrigin = this.OnewayDestination;
    this.OnewayDestination = tempOnewayOrigin;

    let tempRoundOrigin = this.RoundTripOrigin;
    this.RoundTripOrigin = this.RoundTripDestination;
    this.RoundTripDestination = tempRoundOrigin;

  }

  dropDownSelectionChange = (event) => {
    const { name, value } = event.target;
    let infant = 0;
    if (name === "adult") {
      const adultValue = parseInt(value, 10);
      const childValue = Math.min(this.state.child, 9 - adultValue);
      const infantValue = Math.min(this.state.infant, adultValue);
      infant = infantValue;
      this.setState({
        adult: adultValue,
        child: childValue,
        infant: infantValue
      });
    } else if (name === "child") {
      const childValue = parseInt(value, 10);
      const adultValue = Math.min(this.state.adult, 10 - childValue);
      const infantValue = Math.min(this.state.infant, adultValue);
      this.setState({
        adult: adultValue,
        child: childValue,
        infant: infantValue
      });
    } else if (name === "infant") {
      const infantValue = parseInt(value, 10);
      infant = infantValue;
      this.setState({
        infant: infantValue
      });
    }


    let testData = {}, testData1 = {};
    testData = this.state.RequestBody;
    testData1 = this.state.OnewayFullSearchResult;


    if (event.target.name == "adult" || event.target.name == "child" || event.target.name == "infant") {
      if (event.target.name == "adult") {
        testData.adult = Number(event.target.value);
        testData1.adult = Number(event.target.value);
        testData.infant = infant;
      }
      else if (event.target.name == "child") {
        testData.child = Number(event.target.value)
        testData1.adult = Number(event.target.value)
      }
      else if (event.target.name == "infant") {
        testData.infant = Number(event.target.value);
        testData1.adult = Number(event.target.value);
      }

      this.setState({
        RequestBody: testData,
        OnewayFullSearchResult: testData1
      })

      return;
    }
    else if (event.target.name == "airlineClass") {
      testData.airlineClass = event.target.value;
      this.setState({
        RequestBody: testData,
        OnewayFullSearchResult: testData1
      })
    }
  }

  setStartDate(date) {
    let RoundTripFullSearchResult = this.state.RoundTripFullSearchResult;
    let selectedDate = dateFormat(date, "yyyy-mm-dd");
    RoundTripFullSearchResult.departureDate = selectedDate;
    let RequestBody = this.state.RequestBody;
    RequestBody.objSectorList[0].departureDate = selectedDate;

    this.setState({
      RoundTripFullSearchResult: RoundTripFullSearchResult,
      RequestBody: RequestBody,
    })

    //set Round Trip Section...
    this.setTravelDateRoundTrip(date, "S", "setStartDate");
  }

  setTravelDateRoundTrip = (date, type, from) => {

    let RoundTripFullSearchResult = this.state.RoundTripFullSearchResult;
    let selectedDate = dateFormat(date, "yyyy-mm-dd");
    if (type == "S") {
      RoundTripFullSearchResult.departureDate = selectedDate;

      let RoundTripOnwardDate = selectedDate;
      if (selectedDate > RoundTripFullSearchResult.returnDate) //Departure date GREATER THAN return Date...
      {
        let RoundTripReturnDate = selectedDate;
        RoundTripFullSearchResult.returnDate = selectedDate;
        //Set Return Date...      
        this.setState({
          RoundTripReturnDate: RoundTripReturnDate,
          RoundTripFullSearchResult: RoundTripFullSearchResult,
        })
      }
      this.setState({

        RoundTripFullSearchResult: RoundTripFullSearchResult,
        RoundTripOnwardDate: RoundTripOnwardDate,
      })

      //Oneway...
      let RequestBody = this.state.RequestBody;
      RequestBody.objSectorList[0].departureDate = selectedDate;
      let OnewayFullSearchResult = this.state.OnewayFullSearchResult;
      OnewayFullSearchResult.departureDate = selectedDate;
      this.setState({
        RequestBody: RequestBody,
        OnewayFullSearchResult: OnewayFullSearchResult
      })
    }
    else {
      let RoundTripReturnDate = selectedDate;
      RoundTripFullSearchResult.returnDate = selectedDate;

      //Set Return Date...      
      this.setState({
        RoundTripReturnDate: RoundTripReturnDate,
        RoundTripFullSearchResult: RoundTripFullSearchResult,

      })
    }


  }

  ModifyRoundSearchFlights = (type) => {

    //Check network connection...
    if (!CheckNetworkStatus())
      return;

    if (this.state.originErrorR == false && this.state.destinationErrorR == false) {
      const {
        RoundTripOriginSearchTerm,
        RoundTripDestinationSearchTerm,
      } = this.state;
      if (!RoundTripOriginSearchTerm || !RoundTripDestinationSearchTerm) {
        this.setState({ showValidationMessage: true });
        return;
      }
      else {
        this.setState({ showValidationMessage: false });
        let travelTypeToCheck;
        let requestToSend = this.state.RequestBody;

        let data = [];
        data.push(this.state.originSectorRound);
        data.push(this.state.destinationSectorRound);


        let onwardObj = {}, returnObj = {};

        onwardObj.departureDate = dateFormat(this.state.RoundTripOnwardDate, "yyyy-mm-dd")
        onwardObj.origin = this.state.RoundTripOriginAirport.code
        onwardObj.origincountry = this.state.RoundTripOriginAirport.countryCode
        onwardObj.destination = this.state.RoundTripDestinationAirport.code
        onwardObj.destinationcountry = this.state.RoundTripDestinationAirport.countryCode

        returnObj.departureDate = dateFormat(this.state.RoundTripReturnDate, "yyyy-mm-dd")
        returnObj.origin = this.state.RoundTripDestinationAirport.code
        returnObj.origincountry = this.state.RoundTripDestinationAirport.countryCode
        returnObj.destination = this.state.RoundTripOriginAirport.code
        returnObj.destinationcountry = this.state.RoundTripOriginAirport.countryCode


        requestToSend.objSectorList = [onwardObj, returnObj]

        //TravelType...

        if (requestToSend.objSectorList[0].origincountry != "IN" || requestToSend.objSectorList[0].destinationcountry != "IN") {
          requestToSend.traveltype = "I"

          travelTypeToCheck = "I"
        }
        else {
          requestToSend.traveltype = "D"
          travelTypeToCheck = "D"
        }
        requestToSend.objSectorList[0].Tripmode = "R"
        requestToSend.objSectorList[1].Tripmode = "R"
        this.setState({
          RequestBody: requestToSend,
          OnewayFullSearchResult: requestToSend
        });




        //Navigation....
        if (type == "O")
          this.props.navigate('/Oneway');
        else if (type == "R") {
          if (travelTypeToCheck == "D") {
            if (window.location.pathname == "/Roundtrip") {
              let url = "/Roundtrip?IRTSearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
              this.props.navigate(url);
              window.location.reload();
            }
            else {
              let url = "/Roundtrip?IRTSearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
              this.props.navigate(url);
            }
          }
          else {
            if (window.location.pathname == "/InternationalRoundTrip") {
              let url = "/InternationalRoundTrip?CRTSearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
              this.props.navigate(url);
              window.location.reload();
            }
            else {
              //this.props.navigate('/InternationalRoundTrip');   //Combained RoundTrip
              let url = "/InternationalRoundTrip?CRTSearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
              this.props.navigate(url);
            }
          }
        }
        else if (type == "S")
          this.props.navigate('/International');

        else if (type == "M")
          this.props.navigate('/Multicity');
      }


    }
  }

  // getSearchResult() {
  //   // if (!this.state.isLoaded) {
  //   GetOneWaySearch(this.requestBody).then((response) => {
  //     if(!response.data.status)
  //     {
  //       if(response.data.responseMessage=="No Result Found")
  //       {
  //         this.setState({
  //           isResultFound:true
  //         })

  //       }
  //     }
  //     if (response.data.status) {

  //       this.setState({
  //         LowerFareResult: response.data.objlowfareList,
  //         ItinSearchResult: response.data.objItinList,
  //         ItinSearchResultCopy: response.data.objItinList,
  //         AvlAirlineResult: response.data.objAvlairlineList,
  //         //pricingList: response.data.objItinList.pricingList,
  //         OnewayFullSearchResult: response.data,

  //         isLoaded: true,
  //       });

  //       // let filterPrice={};
  //       // filterPrice.priceRange=this.state.OnewayFullSearchResult.maximumFare;
  //       this.setState({
  //         priceRange: response.data.maximumFare,
  //         OnewayOriginSearchTerm:response.data.origin,
  //         OnewayDestinationSearchTerm:response.data.destination,
  //         RoundTripOriginSearchTerm:response.data.origin,
  //         RoundTripDestinationSearchTerm:response.data.destination,

  //       })

  //       let dataForModifySearch=this.state.RoundTripFullSearchResult;
  //       dataForModifySearch.departureDate=response.data.objItinList[0].departureDate;
  //       dataForModifySearch.returnDate=response.data.objItinList[0].departureDate;
  //       this.setState({ 
  //         RoundTripFullSearchResult:dataForModifySearch,
  //         RoundTripOnwardDate:response.data.objItinList[0].departureDate,
  //         RoundTripReturnDate:response.data.objItinList[0].departureDate,

  //       })



  //     }

  //   })
  //   .catch((error) => {
  //     if(error.response.status == 403 && !window.navigator.onLine){
  //       this.props.navigate('/issue');
  //     }
  //     else if(error.response.data.responseMessage=="InvalidToken")
  //     { 

  //       localStorage.removeItem("TM_UDetails");
  //           localStorage.removeItem("tokenValue");
  //           localStorage.removeItem("onewaySearchResult");
  //           toast.success('Unauthorized Access...', {
  //               position: 'top-right',
  //               autoClose: 3000,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: true,
  //               draggable: true,
  //               progress: undefined,
  //               theme: 'colored',
  //             });

  //         this.props.navigate('/');        
  //     }
  //       // toast.success('Something went wrong...', {
  //       //   position: 'top-right',
  //       //   autoClose: 3000,
  //       //   hideProgressBar: false,
  //       //   closeOnClick: true,
  //       //   pauseOnHover: true,
  //       //   draggable: true,
  //       //   progress: undefined,
  //       //   theme: 'colored',
  //       // });

  //   });
  //   //}    
  // }

  ResetFilter() {

    this.setState({
      Airlines: [],
      priceRange: 0,
      Stops: [],
      DepartureTimes: [],
      ArrivalTimes: [],
    });

  }

  SearchFlights = (type) => {

    //Check network connection...
    if (!CheckNetworkStatus())
      return;

    if (this.state.originError == false && this.state.destinationError == false) {
      const {
        OnewayOriginSearchTerm,
        OnewayDestinationSearchTerm,
      } = this.state;
      if (!OnewayOriginSearchTerm || !OnewayDestinationSearchTerm) {
        this.setState({ showValidationMessage: true });
        return;
      }
      else {
        this.setState({ showValidationMessage: false });
        let travelTypeToCheck;
        let requestToSend = this.state.RequestBody;

        let data = {}
        data.departureDate = this.state.OnewayFullSearchResult.departureDate;
        data.origin = this.state.OnewayOriginAirport.code;
        data.origincountry = this.state.OnewayOriginAirport.countryCode;
        data.destination = this.state.OnewayDestinationAirport.code;
        data.destinationcountry = this.state.OnewayDestinationAirport.countryCode;
        data.Tripmode = type;

        requestToSend.objSectorList = [data];

        //TravelType...
        if (requestToSend.objSectorList[0].origincountry != "IN" || requestToSend.objSectorList[0].destinationcountry != "IN") {
          requestToSend.traveltype = "I"
          travelTypeToCheck = "I"
        }
        else {
          requestToSend.traveltype = "D"
          travelTypeToCheck = "D"
        }
        this.setState({
          RequestBody: requestToSend,
          OnewayFullSearchResult: requestToSend
        });



        // localStorage.setItem("onewaySearchResult", JSON.stringify(requestToSend));
        //Navigation....
        if (type == "O") {
          let url = "/Oneway?onewaySearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
          this.props.navigate(url);
          // this.props.parentFunction();
          // this.componentDidMount();
          // this.props.navigate('/Oneway');

          if (window.location.pathname == "/Oneway") {
            window.location.reload();
          }
        }
        else if (type == "R") {
          if (travelTypeToCheck == "D")
            this.props.navigate('/Roundtrip');
          else
            this.props.navigate('/InternationalRoundTrip');   //Combained RoundTrip
        }
        else if (type == "S")
          this.props.navigate('/International');

        else if (type == "M")
          this.props.navigate('/Multicity');
      }

    }

  }

  inputOnewaySearchRequestChangeHandlerRoundTrip = (event) => {
    let testData = {}


    const { name, value } = event.target;
    let adult = this.state.adult;
    let child = this.state.child;
    let infant = 0;
    if (name === "adult") {
      const adultValue = parseInt(value, 10);
      const childValue = Math.min(this.state.child, 9 - adultValue);
      const infantValue = Math.min(this.state.infant, adultValue);
      infant = infantValue;
      this.setState({
        adult: adultValue,
        child: childValue,
        infant: infantValue
      });
      adult = adultValue;
      child = childValue;
      infant = infantValue;
    } else if (name === "child") {
      const childValue = parseInt(value, 10);
      const adultValue = Math.min(this.state.adult, 9 - childValue);
      const infantValue = Math.min(this.state.infant, adultValue);
      this.setState({
        adult: adultValue,
        child: childValue,
        infant: infantValue
      });
      adult = adultValue;
      child = childValue;
      infant = infantValue;
    } else if (name === "infant") {
      const infantValue = parseInt(value, 10);
      infant = infantValue;
      this.setState({
        infant: infantValue
      });
      infant = infantValue;
    }


    testData = this.state.RequestBody;

    if (event.target.name == "adult" || event.target.name == "child" || event.target.name == "infant") {
      // if (event.target.name == "adult") {
      //   testData.adult = Number(event.target.value)
      // }
      // else if (event.target.name == "child") {
      //   testData.child = Number(event.target.value)
      // }
      // else if (event.target.name == "infant") {
      //   testData.infant = Number(event.target.value)
      // }


      // this.setState({
      //   RequestBody: testData,
      //   OnewayFullSearchResult: testData
      // })


      testData.adult = adult;
      testData.child = child;
      testData.infant = infant;


      //setRequestBody({ ...requestBody, [name]: Number(value) });
      return;
    }
    else if (event.target.name == "airlineClass") {
      testData.airlineClass = event.target.value
      this.setState({
        RequestBody: testData,
        OnewayFullSearchResult: testData
      })
    }
  }

  getAllAirports() {
    GetAirportList().then((response) => {
      let originCityName, destinationCityName;
      if (response.data.status) {
        this.allAirportGlobal = response.data.objAirportList;
        this.setState({
          AirportJson: response.data.objAirportList,
        })

        this.allAirportGlobal.filter((item) => {
          if (item.code == this.OnewayOrigin) {
            originCityName = item.code + "-" + item.cityName + "-" + item.airportName + "-" + item.countryCode;
          }
          else if (item.code == this.OnewayDestination) {
            destinationCityName = item.code + "-" + item.cityName + "-" + item.airportName + "-" + item.countryCode;
          }
        })

        this.setState({
          OnewayOriginSearchTerm: originCityName,
          OnewayDestinationSearchTerm: destinationCityName,

          RoundTripOriginSearchTerm: originCityName,
          RoundTripDestinationSearchTerm: destinationCityName
        })
      }





    })
  }

  handleKeyDown = (event, airportListLength) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      this.handleArrowUp();
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      this.handleArrowDown();
    }
    else if ((event.key === "Enter" || event.key === "Tab") && airportListLength > 0) {
      event.preventDefault();
      this.handleEnterKey(event);
    }
  };

  handleArrowUp = () => {
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4, OnewayOriginSearchResults, OnewayDestinationSearchResults, RoundTripOriginSearchResults, RoundTripDestinationSearchResults } = this.state;
    const newIndex = selectedIndex > 0 ? selectedIndex - 1 : OnewayOriginSearchResults.length - 1;
    const newIndex2 = selectedIndex2 > 0 ? selectedIndex2 - 1 : OnewayDestinationSearchResults.length - 1;
    const newIndex3 = selectedIndex3 > 0 ? selectedIndex3 - 1 : RoundTripOriginSearchResults.length - 1;
    const newIndex4 = selectedIndex4 > 0 ? selectedIndex4 - 1 : RoundTripDestinationSearchResults.length - 1;

    if (newIndex > -1 && newIndex < OnewayOriginSearchResults.length) {
      //if(newIndex01 == -1)

      //newIndex01 = 0;
      let AirportDetails = OnewayOriginSearchResults[newIndex];
      let selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ OnewayOriginSearchTerm: selectedAirport });
    }
    if (newIndex2 > -1 && newIndex2 < OnewayDestinationSearchResults.length) {
      //if(newIndex02 == -1)

      //newIndex02 = 0;
      let AirportDetails = OnewayDestinationSearchResults[newIndex2];
      let selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ OnewayDestinationSearchTerm: selectedAirport });
    }
    if (newIndex3 > -1 && newIndex3 < RoundTripOriginSearchResults.length) {
      //if(newIndex03 == -1)

      //newIndex03 = 0;
      let AirportDetails = RoundTripOriginSearchResults[newIndex3];
      let selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ RoundTripOriginSearchTerm: selectedAirport });
    }
    if (newIndex4 > -1 && newIndex4 < RoundTripDestinationSearchResults.length) {
      // if(newIndex04 == -1)

      // newIndex04 = 0;
      let AirportDetails = RoundTripDestinationSearchResults[newIndex4];
      let selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ RoundTripDestinationSearchTerm: selectedAirport });

    }


    this.setState({ selectedIndex: newIndex, selectedIndex2: newIndex2, selectedIndex3: newIndex3, selectedIndex4: newIndex4 }, () => {
      this.scrollSelectedIntoView();
    });
  };

  handleArrowDown = () => {
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4, OnewayOriginSearchResults, OnewayDestinationSearchResults, RoundTripOriginSearchResults, RoundTripDestinationSearchResults } = this.state;
    const newIndex = selectedIndex < OnewayOriginSearchResults.length - 1 ? selectedIndex + 1 : 0;
    const newIndex2 = selectedIndex2 < OnewayDestinationSearchResults.length - 1 ? selectedIndex2 + 1 : 0;
    const newIndex3 = selectedIndex3 < RoundTripOriginSearchResults.length - 1 ? selectedIndex3 + 1 : 0;
    const newIndex4 = selectedIndex4 < RoundTripDestinationSearchResults.length - 1 ? selectedIndex4 + 1 : 0;

    if (newIndex >= -1 && newIndex < OnewayOriginSearchResults.length) {
      if (newIndex == -1)

        newIndex = 0;
      const AirportDetails = OnewayOriginSearchResults[newIndex];
      const selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ OnewayOriginSearchTerm: selectedAirport });
    }
    if (newIndex2 >= -1 && newIndex2 < OnewayDestinationSearchResults.length) {
      if (newIndex2 == -1)

        newIndex2 = 0;
      const AirportDetails = OnewayDestinationSearchResults[newIndex2];
      const selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ OnewayDestinationSearchTerm: selectedAirport });

    }
    if (newIndex3 >= -1 && newIndex3 < RoundTripOriginSearchResults.length) {
      if (newIndex3 == -1)

        newIndex3 = 0;
      const AirportDetails = RoundTripOriginSearchResults[newIndex3];
      const selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ RoundTripOriginSearchTerm: selectedAirport });
    }
    if (newIndex4 >= -1 && newIndex4 < RoundTripDestinationSearchResults.length) {
      if (newIndex4 == -1)

        newIndex4 = 0;
      const AirportDetails = RoundTripDestinationSearchResults[newIndex4];
      const selectedAirport = `${AirportDetails.code}-${AirportDetails.cityName}-${AirportDetails.airportName}-${AirportDetails.countryCode}`;
      this.setState({ RoundTripDestinationSearchTerm: selectedAirport });

    }

    this.setState({ selectedIndex: newIndex, selectedIndex2: newIndex2, selectedIndex3: newIndex3, selectedIndex4: newIndex4 }, () => {
      this.scrollSelectedIntoView();
    });
  };

  scrollSelectedIntoView = () => {
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4 } = this.state;
    if (this.autoSuggestResultRef.current) {
      const selectedElement = this.autoSuggestResultRef.current.querySelector(`[data-index="${selectedIndex}"]`);
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef2.current) {
      const selectedElement2 = this.autoSuggestResultRef2.current.querySelector(`[data-index="${selectedIndex2}"]`);
      if (selectedElement2) {
        selectedElement2.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef3.current) {
      const selectedElement3 = this.autoSuggestResultRef3.current.querySelector(`[data-index="${selectedIndex3}"]`);
      if (selectedElement3) {
        selectedElement3.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef4.current) {
      const selectedElement4 = this.autoSuggestResultRef4.current.querySelector(`[data-index="${selectedIndex4}"]`);
      if (selectedElement4) {
        selectedElement4.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  };

  handleEnterKey = (event) => {
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4, OnewayOriginSearchResults, OnewayDestinationSearchResults, RoundTripOriginSearchResults, RoundTripDestinationSearchResults } = this.state;
    let defaultIndex = selectedIndex;
    let defaultIndex2 = selectedIndex2;
    let defaultIndex3 = selectedIndex3;
    let defaultIndex4 = selectedIndex4;
    if (defaultIndex >= -1 && defaultIndex < OnewayOriginSearchResults.length) {
      if (defaultIndex == -1)

        defaultIndex = 0;
      const selectedAirport = OnewayOriginSearchResults[defaultIndex];
      if (event.target.name == "onewayOrigin") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "onewayOrigin",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        this.targetTextBoxRef.current.focus();
        this.setState({ originError: false });
        return
      }
    }
    if (defaultIndex2 >= -1 && defaultIndex2 < OnewayDestinationSearchResults.length) {
      if (defaultIndex2 == -1)

        defaultIndex2 = 0;
      const selectedAirport = OnewayDestinationSearchResults[defaultIndex2];
      if (event.target.name == "onewayDestination") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "onewayDestination",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        const {
          OnewayOriginSearchTerm,
        } = this.state;

        if (this.OnewayDestination == this.OnewayOrigin) {
          this.setState({ SameAirportsError: true });
          return;
        }
        else {
          this.setState({ SameAirportsError: false });
          if (this.datePickerRef && this.datePickerRef.current && this.datePickerRef.current.input) {
            this.datePickerRef.current.input.focus();
            this.setState({
              destinationError: false,
              SameAirportsError: false
            });
          }
          return
        }



      }

    }
    if (defaultIndex3 >= -1 && defaultIndex3 < RoundTripOriginSearchResults.length) {
      if (defaultIndex3 == -1)

        defaultIndex3 = 0;
      const selectedAirport = RoundTripOriginSearchResults[defaultIndex3];
      if (event.target.name == "RoundTripOrigin") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "RoundTripOrigin",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        this.targetTextBoxRef2.current.focus();
        this.setState({ originErrorR: false });
        return
      }
    }
    if (defaultIndex4 >= -1 && defaultIndex4 < RoundTripDestinationSearchResults.length) {
      if (defaultIndex4 == -1)

        defaultIndex4 = 0;
      const selectedAirport = RoundTripDestinationSearchResults[defaultIndex4];
      if (event.target.name == "RoundTripDestination") {
        this.handleSelectAirport(
          selectedAirport.code,
          selectedAirport.airportName,
          "RoundTripDestination",
          selectedAirport,
          selectedAirport.countryCode,
          selectedAirport.cityName
        );
        const {
          RoundTripOriginSearchTerm,
        } = this.state;

        if (this.RoundTripDestination == RoundTripOriginSearchTerm) {
          this.setState({ SameAirportsError: true });
          return;
        }
        else {
          if (this.datePickerRef2 && this.datePickerRef2.current && this.datePickerRef2.current.input) {
            this.datePickerRef2.current.input.focus();
            this.setState({ destinationErrorR: false });
          }
          return
        }
      }
    }

    //Reset same Airport Error...
    if (this.RoundTripOrigin == this.RoundTripDestination) {

      this.setState({ SameAirportsError: true });
      return;
    }
    else {

      this.setState({ SameAirportsError: false });
      if (this.datePickerRef && this.datePickerRef.current && this.datePickerRef.current.input) {
        this.datePickerRef.current.input.focus();
        this.setState({
          destinationError: false,
          SameAirportsError: false
        });
      }
      return
    }

  };

  SelectAirport = (code, name, type, airportDetails) => {
    this.handleSelectAirport(code, name, type, airportDetails, airportDetails.countryCode, airportDetails.cityName);
    if (type == "onewayOrigin") {
      if (this.OnewayDestination == this.OnewayOrigin) {
        this.setState({ SameAirportsError: true });
        return;
      }
      else {
        this.setState({ SameAirportsError: false });
        this.targetTextBoxRef.current.focus();
        this.setState({ originError: false });
        return
      }
    }
    else if (type == "onewayDestination") {
      if (this.OnewayDestination == this.OnewayOrigin) {
        this.setState({ SameAirportsError: true });
        return;
      }
      else {
        this.setState({ SameAirportsError: false });
        if (this.datePickerRef && this.datePickerRef.current && this.datePickerRef.current.input) {
          this.datePickerRef.current.input.focus();
          this.setState({ destinationError: false });
        }
        return
      }
    }
    else if (type == "RoundTripOrigin") {
      if (this.RoundTripDestination == this.RoundTripOrigin) {
        this.setState({ SameAirportsError: true });
        return;
      }
      else {
        this.setState({ SameAirportsError: false });
        this.targetTextBoxRef2.current.focus();
        this.setState({ originErrorR: false });
        return
      }
    }
    else if (type == "RoundTripDestination") {
      if (this.RoundTripDestination == this.RoundTripOrigin) {
        this.setState({ SameAirportsError: true });
        return;
      }
      else {
        this.setState({ SameAirportsError: false });
        if (this.datePickerRef2 && this.datePickerRef2.current && this.datePickerRef2.current.input) {
          this.datePickerRef2.current.input.focus();
          this.setState({ destinationErrorR: false });
        }
        return
      }
    }
  };

  handleSearch = (event) => {
    let airportsTemp = this.state.AirportJson;

    const { value } = event.target;
    if (event.target.name == "onewayOrigin") {
      this.setState({
        OnewayOriginSearchTerm: value,
        OnewayOriginAirport: [],
        selectedIndex: -1
      });
      this.OnewayOrigin = "";

    }
    else if (event.target.name == "onewayDestination") {
      this.setState({
        OnewayDestinationSearchTerm: value,
        OnewayDestinationAirport: [],
        selectedIndex2: -1
      });
      this.OnewayDestination = ""
    }
    else if (event.target.name == "RoundTripOrigin") {
      this.setState({
        RoundTripOriginSearchTerm: value,
        RoundTripOriginAirport: [],
        selectedIndex3: -1
      });
      this.RoundTripOrigin = ""
    }
    else if (event.target.name == "RoundTripDestination") {
      this.setState({
        RoundTripDestinationSearchTerm: value,
        RoundTripDestinationAirport: [],
        selectedIndex4: -1
      });
      this.RoundTripDestination = ""
    }


    if (value.length >= 2) {
      let index = -1;
      let SortedAirportArray = [];
      const matchingAirports = airportsTemp.filter(
        (airport) =>
          airport.code.toLowerCase().includes(value.toLowerCase()) ||
          airport.cityCode.toLowerCase().includes(value.toLowerCase()) ||
          airport.airportName.toLowerCase().includes(value.toLowerCase()) ||
          airport.countryCode.toLowerCase().includes(value.toLowerCase()) ||
          airport.cityName.toLowerCase().includes(value.toLowerCase())
      );

      // selected code to top of the Array.apply..
      for (let i = 0; i < matchingAirports.length; i++) {
        if (matchingAirports[i].code.toLowerCase() == value.toLowerCase()) {
          index = i;
          break;

        }
        else if (matchingAirports[i].code.toLowerCase() === matchingAirports[i].cityCode.toLowerCase()) {
          index = i;
        }
      }

      if (index >= 0) {
        SortedAirportArray.push(matchingAirports[index]);
        for (let j = 0; j < matchingAirports.length; j++) {
          if (index != j) {
            SortedAirportArray.push(matchingAirports[j]);
          }
        }
      }
      else {
        SortedAirportArray = [];
        SortedAirportArray = matchingAirports;
      }

      if (SortedAirportArray.length === 1) {
        if (event.target.name == "onewayOrigin") {
          this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "onewayOrigin", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);
          this.targetTextBoxRef.current.focus();

          if (this.OnewayDestination == this.OnewayOrigin) {
            this.setState({ SameAirportsError: true });
            return;
          }
          else {
            this.targetTextBoxRef.current.focus();
            this.setState({
              originError: false,
              SameAirportsError: false
            });
            return
          }
        }
        else if (event.target.name == "onewayDestination") {
          this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "onewayDestination", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);

          if (this.OnewayDestination == this.OnewayOrigin) {
            this.setState({ SameAirportsError: true });
            return;
          }
          else {
            if (this.datePickerRef && this.datePickerRef.current && this.datePickerRef.current.input) {
              this.datePickerRef.current.input.focus();
              this.setState({
                destinationError: false,
                SameAirportsError: false
              });
            }
            return
          }
        }
        else if (event.target.name == "RoundTripOrigin") {
          this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "RoundTripOrigin", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);
          this.targetTextBoxRef2.current.focus();

          if (this.RoundTripOrigin == this.RoundTripDestination) {
            this.setState({ SameAirportsError: true });
            return;
          }
          else {
            this.targetTextBoxRef2.current.focus();
            this.setState({
              originErrorR: false,
              SameAirportsError: false
            });
            return
          }
        }
        else if (event.target.name == "RoundTripDestination") {
          this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "RoundTripDestination", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);

          if (this.RoundTripOrigin == this.RoundTripDestination) {
            this.setState({ SameAirportsError: true });
            return;
          }
          else {
            if (this.datePickerRef2 && this.datePickerRef2.current && this.datePickerRef2.current.input) {
              this.datePickerRef2.current.input.focus();
              this.setState({
                destinationErrorR: false,
                SameAirportsError: false
              });
            }
            return
          }
        }
      }
      else {
        if (event.target.name == "onewayOrigin") {
          this.setState({ originError: true });
        }
        else if (event.target.name == "onewayDestination") {
          this.setState({ destinationError: true });
        }
        else if (event.target.name == "RoundTripOrigin") {
          this.setState({ originErrorR: true });
        }
        else if (event.target.name == "RoundTripDestination") {
          this.setState({ destinationErrorR: true });
        }
      }


      if (event.target.name == "onewayOrigin") {
        this.setState({ OnewayOriginSearchResults: SortedAirportArray });
      }
      else if (event.target.name == "onewayDestination") {

        this.setState({ OnewayDestinationSearchResults: SortedAirportArray });
      }
      else if (event.target.name == "RoundTripOrigin") {

        this.setState({ RoundTripOriginSearchResults: SortedAirportArray });
      }
      else if (event.target.name == "RoundTripDestination") {

        this.setState({ RoundTripDestinationSearchResults: SortedAirportArray });
      }
    }
    else if (event.target.value == "")   //IF nothing in the textbox..
    {
      if (event.target.name == "onewayOrigin" || event.target.name == "RoundTripOrigin") {
        this.OnewayOrigin = "";
        this.RoundTripOrigin = "";

        this.setState({
          SameAirportsError: false,
          OnewayOriginSearchTerm: "",
          RoundTripOriginSearchTerm: "",
          OnewayOriginAirport: {},
          RoundTripOriginAirport: {},
        });

      }
      else if (event.target.name == "onewayDestination" || event.target.name == "RoundTripDestination") {
        this.OnewayDestination = "";
        this.RoundTripDestination = "";

        this.setState({
          SameAirportsError: false,
          OnewayDestinationSearchTerm: "",
          RoundTripDestinationSearchTerm: "",
          OnewayDestinationAirport: {},
          RoundTripDestinationAirport: {},
        });
      }


    }
    else {
      if (event.target.name == "onewayOrigin") {
        this.setState({ OnewayOriginSearchResults: [] });
      }
      else if (event.target.name == "onewayDestination") {
        this.setState({ OnewayDestinationSearchResults: [] });
      }
      else if (event.target.name == "RoundTripOrigin") {
        this.setState({ RoundTripOriginSearchResults: [] });
      }
      else if (event.target.name == "RoundTripDestination") {
        this.setState({ RoundTripDestinationSearchResults: [] });
      }
    }
  };
  handleSelectAirport = (code, name, type, airportDetails) => {
    const selectedAirport = `${code}-${airportDetails.cityName}-${name}-${airportDetails.countryCode}`;
    if (type == "onewayOrigin") {
      this.OnewayOrigin = code;
      this.setState({
        OnewayOriginSearchTerm: selectedAirport,
        OnewayOriginSearchResults: [],
        OnewayOriginAirport: airportDetails,
        originError: false,
        selectedIndex: -1,

        RoundTripOriginSearchTerm: selectedAirport,
        RoundTripOriginSearchResults: [],
        RoundTripOriginAirport: airportDetails,
        originErrorR: false,
        selectedIndex3: -1,
        OnewayOriginSelected: true,

      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    else if (type == "onewayDestination") {
      this.OnewayDestination = code;
      this.RoundTripDestination = selectedAirport;
      this.setState({
        OnewayDestinationSearchTerm: selectedAirport,
        OnewayDestinationSearchResults: [],
        OnewayDestinationAirport: airportDetails,
        destinationError: false,
        selectedIndex2: -1,

        RoundTripDestinationSearchTerm: selectedAirport,
        RoundTripDestinationSearchResults: [],
        RoundTripDestinationAirport: airportDetails,
        destinationErrorR: false,
        selectedIndex4: -1,
        OnewayDestinationSelected: true,
      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    else if (type == "RoundTripOrigin") {

      this.RoundTripOrigin = code;
      this.setState({
        RoundTripOriginSearchTerm: selectedAirport,
        RoundTripOriginSearchResults: [],
        RoundTripOriginAirport: airportDetails,
        originErrorR: false,
        selectedIndex3: -1,

        OnewayOriginSearchTerm: selectedAirport,
        OnewayOriginSearchResults: [],
        OnewayOriginAirport: airportDetails,
        originError: false,
        selectedIndex: -1,
        RoundTripOriginSelected: true,
      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    else if (type == "RoundTripDestination") {

      this.RoundTripDestination = code;
      // this.RoundTripDestination=selectedAirport;
      this.setState({
        RoundTripDestinationSearchTerm: selectedAirport,
        RoundTripDestinationSearchResults: [],
        RoundTripDestinationAirport: airportDetails,
        destinationErrorR: false,
        selectedIndex4: -1,

        OnewayDestinationSearchTerm: selectedAirport,
        OnewayDestinationSearchResults: [],
        OnewayDestinationAirport: airportDetails,
        destinationError: false,
        selectedIndex2: -1,
        RoundTripDestinationSelected: true,
      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    // this.render();
    // this.inputSearchSectorChangeFocusOut(type)

    //If same Airport...
    if (type == "onewayOrigin" || type == "onewayDestination") {
      if (this.OnewayDestination == this.OnewayOrigin) {
        this.setState({
          SameAirportsError: true
        })
      }
      else {
        this.setState({
          SameAirportsError: false
        })
      }
    }
    else {
      if (this.RoundTripDestination == this.RoundTripOrigin) {
        this.setState({
          SameAirportsError: true
        })
      }
      else {
        this.setState({
          SameAirportsError: false
        })
      }

    }

  };

  changeAirportListShowStatus(FieldName) {
    if (FieldName == "Destination") {
      this.setState({
        OnewayOriginSearchResults: [],
        RoundTripOriginSearchResults: [],
      });

      //IF no-airport selected THEN clear the field...
      if (this.OnewayOrigin == "") {
        this.setState({
          OnewayOriginSearchTerm: "",
        })
      }

      if (this.RoundTripOrigin == "") {
        this.setState({
          RoundTripOriginSearchTerm: "",
        })
      }
    }
    else {
      this.setState({
        OnewayDestinationSearchResults: [],
        RoundTripDestinationSearchResults: [],
      });

      //IF no-airport selected THEN clear the field...
      if (this.OnewayDestination == "") {
        this.setState({
          OnewayDestinationSearchTerm: "",
        })
      }


      if (this.RoundTripDestination == "") {
        this.setState({
          RoundTripDestinationSearchTerm: "",
        })
      }
    }
  }

  handleReturnDateClick = () => {
    document.getElementById('ex1-tab-2').click();

    if (this.endDatePickerRef && this.endDatePickerRef.current && this.endDatePickerRef.current.input) {
      this.endDatePickerRef.current.input.focus();
    }
  };
  toggleDropdown() {
    this.setState((prevState) => ({
      showDropdown: !prevState.showDropdown,
    }));
  }

  handleClickOutside(event) {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
      this.setState({ showDropdown: false });
    }
  }

  toggleRoundDropdown() {
    this.setState((prevState) => ({
      showRoundDropdown: !prevState.showRoundDropdown,
    }));
  }

  handleRoundClickOutside(event) {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
      this.setState({ showRoundDropdown: false });
    }
  }
  closeOneDropdown() {
    this.setState({ showDropdown: false });
  }
  closeRoundDropdown() {
    this.setState({ showRoundDropdown: false });
  }
  render() {

    const { data } = this.props

    //AUtoComplete...
    const { showValidationMessage, SameAirportsError } = this.state;
    const { selectedIndex, selectedIndex2, selectedIndex3, selectedIndex4 } = this.state;
    const { OnewayOriginSearchTerm, OnewayDestinationSearchTerm, OnewayOriginSearchResults, OnewayDestinationSearchResults, RoundTripOriginSearchTerm, RoundTripDestinationSearchTerm, RoundTripOriginSearchResults, RoundTripDestinationSearchResults, originError, destinationError, originErrorR, destinationErrorR } = this.state;
    const showSearchResultsOnewayOrigin = OnewayOriginSearchResults.length > 0;
    const showSearchResultsOnewayDestination = OnewayDestinationSearchResults.length > 0;

    const showSearchResultsRoundTripyOrigin = RoundTripOriginSearchResults.length > 0;
    const showSearchResultsRoundTripDestination = RoundTripDestinationSearchResults.length > 0;

    const showNoResultsMessageOnewayOrigin = RoundTripOriginSearchResults.length >= 2 && !showSearchResultsOnewayOrigin;
    const showNoResultsMessageOnewayDestination = OnewayDestinationSearchResults.length >= 2 && !showSearchResultsOnewayDestination;

    const showNoResultsMessageRoundTripOrigin = OnewayOriginSearchResults.length >= 2 && !showSearchResultsOnewayOrigin;
    const showNoResultsMessageRoundTripDestination = RoundTripDestinationSearchResults.length >= 2 && !showSearchResultsOnewayDestination;

    return this.state.isLoaded ? (
      <div>

        {/* <div className="modal-dialog"> */}
        {/* <div className="modal-content"> */}

        <div>
          <div className="row">
            <div className="col-12">
              <form id="booking-form-inner">
                {/* Pills navs */}
                <ul className="nav nav-pills" id="ex1" role="tablist">
                  <li className="nav-item" role="presentation">

                    <a className={this.state.modifySearchPopUpTab == 1 ? 'active nav-link' : 'nav-link'} id="ex1-tab-1" data-bs-toggle="pill" href="#ex1-pills-1" role="tab" aria-controls="ex1-pills-1" aria-selected="true"  >One Way</a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className={this.state.modifySearchPopUpTab == 2 ? 'active nav-link' : 'nav-link'} id="ex1-tab-2" data-bs-toggle="pill" role="tab" href="#ex1-pills-2" aria-controls="ex1-pills-2" aria-selected="true"   >Round Trip</a>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                                                    <a className="nav-link" id="ex1-tab-3" data-bs-toggle="pill" href="#ex1-pills-3" role="tab" aria-controls="ex1-pills-3" aria-selected="false">Multi City</a>
                                                </li> */}
                </ul>
                {/* Pills navs */}
                {/* Pills content */}
                <div className="tab-content pb-2" id="ex1-content">
                  <div className={this.state.modifySearchPopUpTab == 1 ? 'tab-pane fade show active' : 'tab-pane fade'} id="ex1-pills-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                    <div className="inner-modify">
                      <div className="d-flex flex-row align-items-center justify-content-between">



                        <div className="origin mr-15">
                          <div className="relative-position">
                            <input
                              type="text"
                              value={OnewayOriginSearchTerm}
                              onChange={this.handleSearch}
                              onKeyDown={(e) => { this.handleKeyDown(e, OnewayOriginSearchResults.length) }}
                              onFocus={() => this.changeAirportListShowStatus("Origin")}
                              placeholder="Origin" className="form-control inner-origin"
                              name='onewayOrigin'
                              autoComplete='off' onClick={(e) => e.target.select()}
                              spellCheck="false"
                            />
                            {originError && OnewayOriginSearchTerm.length > 2 && OnewayOriginSearchResults.length == 0 && <div className="error-message">Select valid origin!</div>}
                            {showValidationMessage && !OnewayOriginSearchTerm && (
                              <span style={{ color: 'red' }}>Please enter Departure Airport</span>
                            )}
                            {showSearchResultsOnewayOrigin && OnewayOriginSearchTerm.length > 0 && (
                              <div className="auto-suggest-result" ref={this.autoSuggestResultRef}>
                                {OnewayOriginSearchResults.map((airport, index) => (
                                  <div
                                    key={airport.code}
                                    onClick={() => this.SelectAirport(airport.code, airport.airportName, "onewayOrigin", airport, airport.countryCode, airport.cityName)}
                                    className={index === selectedIndex ? "auto-result-block-selected " : "auto-result-block"}
                                    data-index={index}
                                  >
                                    {
                                      <div className={index === 0 && selectedIndex == -1 ? "auto-result-block-selected " : "auto-result-block"}>
                                        <div className="float-start"><span>{airport.cityName} (<span>{airport.code}</span>)</span><br />
                                          <span>{airport.airportName}</span>
                                        </div>
                                        <div className="float-end pt-2">
                                          <span>{airport.countryCode}</span>
                                        </div>
                                        <div className="clearfix"></div>
                                      </div>
                                    }
                                  </div>
                                ))}
                              </div>
                            )}
                            {!showSearchResultsOnewayOrigin && showNoResultsMessageOnewayOrigin && <div>Sorry, no results found.</div>}
                          </div>

                        </div>






                        <div className="swap-image-inner mr-15">
                          <a onClick={this.swapOriginAndDestination}><img src="assets/img/swap-inner.svg" width={15} alt="" /></a>
                        </div>





                        <div className="origin mr-15">
                          <div className="relative-position">
                            <input
                              ref={this.targetTextBoxRef}
                              type="text"
                              value={OnewayDestinationSearchTerm}
                              onChange={this.handleSearch}
                              onKeyDown={(e) => { this.handleKeyDown(e, OnewayDestinationSearchResults.length) }}
                              onFocus={() => this.changeAirportListShowStatus("Destination")}
                              placeholder="Destination" className="form-control inner-origin"
                              name='onewayDestination'
                              autoComplete='off' onClick={(e) => e.target.select()}
                              spellCheck="false"
                            />
                            {destinationError && OnewayDestinationSearchTerm.length > 2 && OnewayDestinationSearchResults.length == 0 && <div className="error-message">Select valid destination!</div>}
                            {showValidationMessage && !OnewayDestinationSearchTerm && (
                              <span style={{ color: 'red' }}>Please enter Destination Airport</span>
                            )}
                            {SameAirportsError && (
                              <span className="sameaierr">Origin and Destination cannot be the same.</span>
                            )}
                            {showSearchResultsOnewayDestination && OnewayDestinationSearchTerm.length > 0 && (
                              <div className="auto-suggest-result" ref={this.autoSuggestResultRef2}>
                                {OnewayDestinationSearchResults.map((airport, index) => (

                                  <div
                                    key={airport.code}
                                    onClick={() => this.SelectAirport(airport.code, airport.airportName, "onewayDestination", airport, airport.countryCode, airport.cityName)}
                                    className={index === selectedIndex2 ? "auto-result-block-selected " : "auto-result-block"}
                                    data-index={index}
                                  >
                                    {
                                      <div className={index === 0 && selectedIndex2 == -1 ? "auto-result-block-selected " : "auto-result-block"}>
                                        <div className="float-start"><span>{airport.cityName} (<span>{airport.code}</span>)</span><br />
                                          <span>{airport.airportName}</span>
                                        </div>
                                        <div className="float-end pt-2">
                                          <span>{airport.countryCode}</span>
                                        </div>
                                        <div className="clearfix"></div>
                                      </div>
                                    }
                                  </div>
                                ))}
                              </div>
                            )}
                            {!showSearchResultsOnewayDestination && showNoResultsMessageOnewayDestination && <div>Sorry, no results found.</div>}
                          </div>

                        </div>


                        <div className="mr-15">


                          <DatePicker ref={this.datePickerRef} selected={this.startDate} value={dateFormat(this.requestBody.objSectorList[0].departureDate, "dd-mm-yyyy")} onChange={(date) => this.setStartDate(date)} minDate={new Date()} />
                        </div>
                        <div className="mr-15">

                          <div onClick={this.handleReturnDateClick}><DatePicker placeholderText="Return" /></div>
                        </div>

                        <div className="mr-15">
                          <div style={{ position: 'relative', display: 'inline-block' }}>
                            <input
                              type="text"
                              className="form-control pax-drop"
                              onClick={this.toggleDropdown}
                              placeholder="Pax & Class"
                              value={`${parseInt(this.state.RequestBody.adult, 10) + parseInt(this.state.RequestBody.child, 10) + parseInt(this.state.RequestBody.infant, 10)} Pax, ${this.state.RequestBody.airlineClass}`}
                              readOnly
                            />
                            {this.state.showDropdown && (
                              <div
                                className="dropdown-content pax-drop-content"
                                ref={(element) => { this.dropdownRef = element; }}
                              >
                                <div className="container">
                                  <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                      <label className="small-label">Adult (12+)</label>
                                      <select className="form-control" id="Adult" name="adult" value={this.state.RequestBody.adult} onChange={(e) => this.inputOnewaySearchRequestChangeHandlerRoundTrip(e)} fdprocessedid="jtwsm">
                                        {Array.from({ length: 10 - parseInt(this.state.RequestBody.child, 10) }).map((_, index) => (
                                          (index > 0) && <option key={index} value={index}>{index < 10 ? `0${index}` : index}</option>
                                        ))}

                                      </select>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-12">
                                      <label className="small-label">Child (2-11)</label>
                                      <select className="form-control" id="Child" name="child" value={this.state.RequestBody.child} onChange={(e) => this.inputOnewaySearchRequestChangeHandlerRoundTrip(e)} fdprocessedid="pxdj69">
                                        {Array.from({ length: 10 - parseInt(this.state.RequestBody.adult, 10) }).map((_, index) => (
                                          <option key={index} value={index}>{index < 10 ? `0${index}` : index}</option>
                                        ))}

                                      </select>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-12">
                                      <label className="small-label" style={{ width: '100%', position: "absolute" }}>Infant(Below 2yrs)</label>
                                      <select className="form-control" style={{ marginTop: "24px", }} id="Child" name="infant" value={this.state.RequestBody.infant} onChange={(e) => this.inputOnewaySearchRequestChangeHandlerRoundTrip(e)} fdprocessedid="pxdj69">
                                        {Array.from({ length: parseInt(this.state.RequestBody.adult + 1) }).map((_, index) => (
                                          <option key={index} value={index}>{index < 10 ? `0${index}` : index}</option>
                                        ))}

                                      </select>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">

                                      <label className="small-label" style={{ width: '100%' }}>Select Class</label>
                                      <select className="form-control" name="airlineClass" id="airline_class" value={this.state.RequestBody.airlineClass} onChange={(e) => this.dropDownSelectionChange(e)} fdprocessedid="92g40h">
                                        <option value="Economy">Economy</option>
                                        <option value="StandardEconomy">Standard Economy</option>
                                        <option value="PremiumEconomy">Premium Economy</option>
                                        <option value="Business">Business</option>
                                        <option value="PremiumBusiness">Premium Business</option>
                                        <option value="First">First</option>
                                        <option value="PremiumFirst">Premium First</option>
                                      </select>

                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 pt-2">
                                    <button className="btn btn-primary w-100 pax-done"onClick={this.closeOneDropdown}>Done</button>
                                    </div>
                                  </div>
                                </div>




                              </div>
                            )}
                          </div>
                        </div>




                        <div>
                          <button type="button" className="btn btn-primary" onClick={() => this.SearchFlights('O')} disabled={this.state.SameAirportsError || destinationError || originError}>Search</button>
                        </div>






                      </div>
                    </div>
                  </div>
                  <div className={this.state.modifySearchPopUpTab == 2 ? 'tab-pane fade show active' : 'tab-pane fade'} id="ex1-pills-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                    <div className="inner-modify">
                      <div className="d-flex flex-row align-items-center">




                        <div className="origin mr-15">
                          <div className="relative-position">
                            <input
                              type="text"
                              value={RoundTripOriginSearchTerm}
                              onChange={this.handleSearch}
                              onKeyDown={(e) => { this.handleKeyDown(e, RoundTripOriginSearchResults.length) }}
                              onFocus={() => this.changeAirportListShowStatus("Origin")}
                              placeholder="Origin" className="form-control inner-origin"
                              name='RoundTripOrigin'
                              autoComplete='off' onClick={(e) => e.target.select()}
                              spellCheck="false"
                            />
                            {originErrorR && RoundTripOriginSearchTerm.length > 2 && RoundTripOriginSearchResults.length == 0 && <div className="error-message">Select valid origin!</div>}
                            {showValidationMessage && !RoundTripOriginSearchTerm && (
                              <span style={{ color: 'red' }}>Please enter Departure Airport</span>
                            )}
                            {showSearchResultsRoundTripyOrigin && RoundTripOriginSearchTerm.length > 0 && (
                              <div className="auto-suggest-result" ref={this.autoSuggestResultRef3}>
                                {RoundTripOriginSearchResults.map((airport, index) => (
                                  <div
                                    key={airport.code}
                                    onClick={() => this.SelectAirport(airport.code, airport.airportName, "RoundTripOrigin", airport, airport.countryCode, airport.cityName)}
                                    className={index === selectedIndex3 ? "auto-result-block-selected " : "auto-result-block"}
                                    data-index={index}
                                  >
                                    <div className={index === 0 && selectedIndex3 == -1 ? "auto-result-block-selected " : "auto-result-block"}>
                                      <div className="float-start"><span>{airport.cityName} (<span>{airport.code}</span>)</span><br />
                                        <span>{airport.airportName}</span>
                                      </div>
                                      <div className="float-end pt-2">
                                        <span>{airport.countryCode}</span>
                                      </div>
                                      <div className="clearfix"></div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                            {!showSearchResultsRoundTripyOrigin && showNoResultsMessageRoundTripOrigin && <div>Sorry, no results found.</div>}
                          </div>

                        </div>






                        <div className="swap-image-inner mr-15">
                          <a onClick={this.swapOriginAndDestination}><img src="assets/img/swap-inner.svg" width={15} alt="" /></a>
                        </div>




                        <div className="origin mr-15">
                          <div className="relative-position">
                            <input
                              ref={this.targetTextBoxRef2}
                              type="text"
                              value={RoundTripDestinationSearchTerm}
                              onChange={this.handleSearch}
                              onKeyDown={(e) => { this.handleKeyDown(e, RoundTripDestinationSearchResults.length) }}
                              onFocus={() => this.changeAirportListShowStatus("Destination")}
                              placeholder="Destination" className="form-control inner-origin"
                              name='RoundTripDestination'
                              autoComplete='off' onClick={(e) => e.target.select()}
                              spellCheck="false"
                            />
                            {destinationErrorR && RoundTripDestinationSearchTerm.length > 2 && RoundTripDestinationSearchResults.length == 0 && <div className="error-message">Select valid Destination!</div>}
                            {showValidationMessage && !RoundTripDestinationSearchTerm && (
                              <span style={{ color: 'red' }}>Please enter Destination Airport</span>
                            )}
                            {SameAirportsError && (
                              <span className="sameaierr">Origin and Destination cannot be the same.</span>
                            )}
                            {showSearchResultsRoundTripDestination && RoundTripDestinationSearchTerm.length > 0 && (
                              <div className="auto-suggest-result" ref={this.autoSuggestResultRef4}>
                                {RoundTripDestinationSearchResults.map((airport, index) => (
                                  <div
                                    key={airport.code}
                                    onClick={() => this.SelectAirport(airport.code, airport.airportName, "RoundTripDestination", airport, airport.countryCode, airport.cityName)}
                                    className={index === selectedIndex4 ? "auto-result-block-selected " : "auto-result-block"}
                                    data-index={index}
                                  >
                                    <div className={index === 0 && selectedIndex4 == -1 ? "auto-result-block-selected " : "auto-result-block"}>
                                      <div className="float-start"><span>{airport.cityName} (<span>{airport.code}</span>)</span><br />
                                        <span>{airport.airportName}</span>
                                      </div>
                                      <div className="float-end pt-2">
                                        <span>{airport.countryCode}</span>
                                      </div>
                                      <div className="clearfix"></div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                            {!showSearchResultsRoundTripDestination && showNoResultsMessageRoundTripDestination && <div>Sorry, no results found.</div>}
                          </div>

                        </div>







                        <div className="mr-15"><DatePicker ref={this.datePickerRef2} name='startDate' value={dateFormat(this.state.RoundTripFullSearchResult.departureDate, "dd-mm-yyyy")} selected={this.startDate} onChange={(date) => this.setTravelDateRoundTrip(date, "S")} minDate={new Date()} /></div>




                        <div className="mr-15"><DatePicker ref={this.endDatePickerRef} name='endDate' value={dateFormat(this.state.RoundTripFullSearchResult.returnDate, "dd-mm-yyyy")} selected={this.endDate} onChange={(date) => this.setTravelDateRoundTrip(date, "R")} minDate={Date.parse(this.state.RoundTripFullSearchResult.departureDate)} /></div>

                        <div className="mr-15">
                          <div style={{ position: 'relative', display: 'inline-block' }}>
                            <input
                              type="text"
                              className="form-control pax-drop"
                              onClick={this.toggleRoundDropdown} placeholder="Pax & Class"
                              value={`${parseInt(this.state.RequestBody.adult, 10) + parseInt(this.state.RequestBody.child, 10) + parseInt(this.state.RequestBody.infant, 10)} Pax, ${this.state.RequestBody.airlineClass}`}
                              readOnly
                            />
                            {this.state.showRoundDropdown && (
                              <div
                                className="dropdown-content pax-drop-content"
                                ref={(element) => { this.dropdownRef = element; }}
                              >
                                <div className="container">
                                  <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                      <label className="small-label">Adult (12+)</label>
                                      <select className="form-control" id="Adult" name="adult" value={this.state.RequestBody.adult} onChange={(e) => this.inputOnewaySearchRequestChangeHandlerRoundTrip(e)} fdprocessedid="jtwsm">
                                        {Array.from({ length: 10 - parseInt(this.state.RequestBody.child, 10) }).map((_, index) => (
                                          (index > 0) && <option key={index} value={index}>{index < 10 ? `0${index}` : index}</option>
                                        ))}

                                      </select>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-12">
                                      <label className="small-label">Child (2-11)</label>
                                      <select className="form-control" id="Child" name="child" value={this.state.RequestBody.child} onChange={(e) => this.inputOnewaySearchRequestChangeHandlerRoundTrip(e)} fdprocessedid="pxdj69">
                                        {Array.from({ length: 10 - parseInt(this.state.RequestBody.adult, 10) }).map((_, index) => (
                                          <option key={index} value={index}>{index < 10 ? `0${index}` : index}</option>
                                        ))}

                                      </select>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-12">
                                      <label className="small-label" style={{ width: '100%', position: "absolute" }}>Infant(Below 2yrs)</label>
                                      <select className="form-control" style={{ marginTop: "24px", }} id="Child" name="infant" value={this.state.RequestBody.infant} onChange={(e) => this.inputOnewaySearchRequestChangeHandlerRoundTrip(e)} fdprocessedid="pxdj69">
                                        {Array.from({ length: parseInt(this.state.RequestBody.adult + 1) }).map((_, index) => (
                                          <option key={index} value={index}>{index < 10 ? `0${index}` : index}</option>
                                        ))}

                                      </select>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">

                                      <label className="small-label" style={{ width: '100%' }}>Select Class</label>
                                      <select className="form-control" name="airlineClass" id="airline_class" value={this.state.RequestBody.airlineClass} onChange={(e) => this.dropDownSelectionChange(e)} fdprocessedid="92g40h">
                                        <option value="Economy">Economy</option>
                                        <option value="StandardEconomy">Standard Economy</option>
                                        <option value="PremiumEconomy">Premium Economy</option>
                                        <option value="Business">Business</option>
                                        <option value="PremiumBusiness">Premium Business</option>
                                        <option value="First">First</option>
                                        <option value="PremiumFirst">Premium First</option>
                                      </select>

                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 pt-2">
                                    <button className="btn btn-primary w-100 pax-done"onClick={this.closeRoundDropdown}>Done</button>
                                    </div>
                                  </div>
                                </div>




                              </div>
                            )}
                          </div>
                        </div>


                        <div>
                          <button type="button" className="btn btn-primary" onClick={() => this.ModifyRoundSearchFlights('R')} disabled={this.state.SameAirportsError || originErrorR || destinationErrorR}>Search</button>
                        </div>





                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="ex1-pills-3" role="tabpanel" aria-labelledby="ex1-tab-3">


                  </div>

                </div>
                {/* route content */}
              </form>
            </div>
          </div>
        </div>

        {/* </div> */}
        {/* </div> */}

      </div>
    ) : (<div>No data Loaded</div>)



    // return <div> dfg </div>

  }
}


function WithNavigate(props) {
  let navigate = useNavigate();
  return <ModifySearchPopUp {...props} navigate={navigate} />
}

function WithLocation(props) {
  let location = useLocation();
  return <ModifySearchPopUp {...props} location={location} />
}

export default WithNavigate
