import React, { Component } from 'react'
import {Link, useNavigate} from 'react-router-dom';
import { browserHistory, Router, Route } from 'react-router';
import { PostPaymentResponseToBackEnd } from '../../Services/PaymentGateway'
import { GetBookingConfirm } from "../../Services/FlightDetails"
import { AES, enc } from 'crypto-js';
import {URL_EncodeKey} from "../../Services/EncryptionService"

class InitiateBooking extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount()
  {
    const { location } = this.props;
    const params = new URLSearchParams(window.location.search);
    
    //Field paymentData...
    this.submitPaymentDetails(params.get('data'));
  }

  submitPaymentDetails(fieldData)
  {
    let data = {};
    data.bookingKey = fieldData
    GetBookingConfirm(data).then((response)=>{
      //PostPaymentResponseToBackEnd(data).then((response)=>{
        if(response.data.status)
        {
          this.setState({ isLoaded: true });
        }
        //send to other page...
        let data1={};
        data1= response.data;    

        const secretKey = URL_EncodeKey(); // Secret key used for encryption
        const jsonData = JSON.stringify(data1);
        const encryptedData = AES.encrypt(jsonData, secretKey).toString();          
        const bookparam = encodeURIComponent(encryptedData);
          
        this.props.navigate(`/BookingConfirm?BookingConfirmRequest=${bookparam}`)
      })
  }

  render() {
    return (this.state.isLoaded)?(
      <div>Loaded</div>
    ):(
      <div>
        
        <div class="loadingpay">Loading</div>

  </div>
    )
    // return (
    //   <div>InitiateBooking</div>
    // )
  }
}


function WithNavigate(props) {
    let navigate = useNavigate();
    return <InitiateBooking {...props} navigate={navigate} />
  }
  
  export default WithNavigate
