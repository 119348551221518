
import React, { Component } from 'react';
import { isValidateUPI, isCardNumberValid, isValidateNames,isValidateCardNo,isValidateCvv, isValidCVV, isValidCardDOE } from '../../Utilities/Validations';
import { AES, enc } from 'crypto-js';
import { useLocation} from "react-router-dom";
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { GetPaymentDetails, PaymentRequest,GetPaymentGatewayCharge } from '../../Services/PaymentGateway';
import { format } from 'date-fns';
import { GetBookingConfirm } from "../../Services/FlightDetails"
import { useNavigate, json } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import {URL_EncodeKey} from "../../Services/EncryptionService"
import { Modal, Button } from 'react-bootstrap';
class Payment extends Component {

    constructor(props) {
        super(props);    
        this.state = {
          //formSubmitted:false,
          selectedBank: "",
            IsUpiIdValid:false,
            UPIAddress:"",
            RepricingResponse:{},
            ReceivedFromPaymentGet:{},
            BankCodes:[],
            isData : false,
            PaymentRequestBody:{},
            upiID:"",
            cardNumber :"",
            payerName :"",
            cardExpiry :"",
            CVV :"",
            PaymentType:"",
            paymentStatus:false,
            isUpiValid:true,
            isCardNumber:true,
            isCardHolderName:true,
            isCarDOE:true,
            isCardCVV:true,
            paymentGatewayPage:"",
            paymentType:"UPI",
            paymentRequestDetails:{},

            seconds: 0,
            minutes: 5,
            showInfoModal: false,
        };
        //this.SubmitUPIAddress = this.SubmitUPIAddress.bind(this);
        this.isFormSubmitted = false;
       }

  componentDidMount()
  {
    let RepricingResponse;
    //Data from UserDetails PAge.....
    const params = new URLSearchParams(window.location.search);
    if(params.get('PaymentConfirmRequest')!== null)
    {
          const BookingConfirmRequest = params.get('PaymentConfirmRequest');
      
        //Same secret key used for encryption
        const secretKey = URL_EncodeKey();
        
        //Decrypt the encrypted data
        const decryptedData = AES.decrypt(BookingConfirmRequest, secretKey).toString(enc.Utf8);
        
        //Parse the decrypted data back to an object
        const parsedData = JSON.parse(decryptedData);
        RepricingResponse = parsedData;

        this.setState({
          RepricingResponse : parsedData
        });

        //Timer...
        this.interval = setInterval(this.updateTime, 1000);
    }


    //Get Payment page...
    GetPaymentDetails(RepricingResponse).then((response)=>{

      //request body for Payment gateway request...
      let paymentRequestData={};
      paymentRequestData.amount= response.data.paidAmount;      

      this.setState({ PaymentType: "UPI" })

      this.setState({ showInfoModal: false })

      this.setState({
        ReceivedFromPaymentGet : response.data,
        BankCodes : response.data.objBankCodes,
        isData : true,
        PaymentRequestBody : paymentRequestData,
      })
    })

  }

  handleDropdownChange = (event) => {
    const selectedBankCode = event.target.value;
    this.setState({ selectedBank: selectedBankCode });
  }

   // Function to handle button click event
   handlePaymentTypeChange = (paymentType) => {

    let data={};
    data.paymentType = paymentType;

    this.setState({ PaymentType: paymentType })
    if(paymentType != "UPI")
      this.setState({ showInfoModal: true })

    let requestBody={};
    //all booking data to payment request body...
    requestBody = this.state.RepricingResponse;

    data.itinId = requestBody.itinId;
    data.fareId = requestBody.fareId;
    data.itinIdR = requestBody.itinIdR;
    data.fareIdR = requestBody.fareIdR;
    data.objPaxList = requestBody.objPaxList;
    data.providerCode = requestBody.providerCode;
    data.providerCodeR = requestBody.providerCodeR;
    data.insuranceId = requestBody.insuranceId;

    GetPaymentGatewayCharge(data).then((response) => {
       //request body for Payment gateway request...
       let paymentRequestData={};
       paymentRequestData.amount= response.data.paidAmount;      
 
 
       this.setState({
         ReceivedFromPaymentGet : response.data,
         isData : true,
         PaymentRequestBody : paymentRequestData,
       })
    })
    .catch((error) => {
      console.error('Error applying promo code:', error);
      if(error.response.data.responseMessage=="InvalidToken")
      {

        localStorage.removeItem("TM_UDetails");
            localStorage.removeItem("tokenValue");
            localStorage.removeItem("onewaySearchResult");
        
        this.props.navigate('/');
        
      }     
        toast.success('Something went wrong...', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
    


    });


  }




  updateTime = () => {
    const { minutes, seconds } = this.state;

    if (minutes === 0 && seconds === 0) {
      clearInterval(this.interval);
       // Display a toast notification
       toast.error(`TIme-out`, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        onClose: () => {
          localStorage.removeItem('timerState');
          // Redirect to the homepage
          this.props.navigate(-1);
      },
    });
    } else {
      if (seconds === 0) {
        this.setState((prevState) => ({
          minutes: prevState.minutes - 1,
          seconds: 59,
        }));
      } else {
        this.setState((prevState) => ({ seconds: prevState.seconds - 1 }));
      }
    }
  };

  submitPaymentRequest(paymentType) {
    let isValid = false;

    let requestBody = {};
    //all booking data to payment request body...
    requestBody = this.state.RepricingResponse;
    let userDetails = JSON.parse(localStorage.getItem("TM_UDetails"));
    requestBody.firstname = userDetails.firstName;
    requestBody.contactEmail = this.state.RepricingResponse.contactEmail;
    requestBody.contactNumber = this.state.RepricingResponse.contactNumber;

    requestBody.amount = this.state.PaymentRequestBody.amount;
    requestBody.paymentType = paymentType;
    requestBody.bankCode = this.state.selectedBank;
    this.setState({ PaymentType: paymentType })
    if (paymentType == "UPI") {
      if (isValidateUPI(this.state.upiID)) {
        requestBody.upiID = this.state.upiID;
        this.setState({ isUpiValid: true });

        //Final Request...
        PaymentRequest(requestBody).then((response) => {

          if (response.data.status == true) {

            this.setState({
              paymentGatewayPage: response.data.postPaymentXml,
            })
  

          }
        })
      }
      else {
        this.setState({ isUpiValid: false });
        return;
      }

    }
    else if (paymentType == "CC" || paymentType == "DC") {
      let updatedState = {};

      if (isValidateCardNo(this.state.cardNumber) && this.state.cardNumber != "") {
        requestBody.cardNumber = this.state.cardNumber;
        updatedState.isCardNumber = true;
      }
      else
        updatedState.isCardNumber = false;


      if (isValidateNames(this.state.payerName) && this.state.payerName != "") {
        requestBody.payerName = this.state.payerName;
        updatedState.isCardHolderName = true;
      }
      else
        updatedState.isCardHolderName = false;


      if (isValidCardDOE(this.state.cardExpiry)) {
        requestBody.cardExpiry = this.state.cardExpiry;
        updatedState.isCarDOE = true;
      }
      else
        updatedState.isCarDOE = false;


      if (isValidateCvv(this.state.CVV) && Number(this.state.CVV) > 0) {
        requestBody.CVV = this.state.CVV;
        updatedState.isCardCVV = true;
      }
      else {
        updatedState.isCardCVV = false;
      }

      this.setState(updatedState, () => {
        if (!this.state.isCardNumber || !this.state.isCardHolderName || !this.state.isCarDOE || !this.state.isCardCVV) {
          isValid = false;
          return;
        }
        else {
          isValid = true;
          //Final Request...
          PaymentRequest(requestBody).then((response) => {

            if (response.data.status == true) {

              this.setState({
                paymentGatewayPage: response.data.postPaymentXml,
              })
    

            }
          })
        }
      });

    }
    else if (paymentType == "NB") {
      if (this.state.selectedBank != "")
      {
        //Final Request...
        PaymentRequest(requestBody).then((response) => {

          if (response.data.status == true) {

            this.setState({
              paymentGatewayPage: response.data.postPaymentXml,
            })


          }
        })
      }
      
    }

 



  }

  // SubmitUPIAddress(){
  //   this.setState({
  //      isFormSubmitted : true,
  //   });
  // }

  selectCardExpiry(date)
  {

    let currentDate = format(date, 'MM/yyyy');
    this.setState({
      cardExpiry : currentDate,
    })

  }

  OnChangeFieldData(event){
        this.setState({
            [event.target.name] : event.target.value,
            //IsUpiIdValid : isValidateUPI(event.target.value), 
        });
  }
//   componentDidUpdate() {

//     const payment_mode = document.getElementsByName("payment_mode");

//     const access_key = document.getElementsByName("access_key");
//     if (payment_mode  && access_key) {

//         var temp = document.getElementById('submitButton');
//         temp.click();
//         // var form1 = document.getElementById('seamless_auto_submit_form');

//         // form1.submit();
//     }

// }

// componentDidUpdate1() {

//   this.sleep(50).then(() => {  
//     document.getElementById('seamless_auto_submit_form').submit();
//   })

// }

// componentDidUpdate1 = () => {
//   if (!this.state.formSubmitted) {
//     this.setState({ formSubmitted: true }, () => {
//       this.sleep(50).then(() => {
//         const form = document.getElementById('seamless_auto_submit_form');
//         if (form) {
//           form.submit();
//         }
//       });
//     });
//   }
// };
componentDidUpdate(prevProps, prevState) {
  if (this.state.paymentGatewayPage && !this.isFormSubmitted && prevState.paymentGatewayPage !== this.state.paymentGatewayPage) {
    this.submitForm();
  }
}

submitForm = () => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = this.state.paymentGatewayPage;
  document.body.appendChild(tempDiv.firstChild);

  const form = document.getElementById('seamless_auto_submit_form');
  if (form) {
    form.submit();
    this.isFormSubmitted = true;
  }
};

sleep = ms => {  
  return new Promise(resolve => setTimeout(resolve, ms));  
  }; 
  
  handleInfoShow = () => {
    this.setState({ showInfoModal: true });
  };

  handleInfoClose = () => {
    this.setState({ showInfoModal: false });
  };

  render() {
    // const { minutes, seconds } = this.state;
    const { minutes, seconds, formSubmitted } = this.state;


    if(this.state.paymentGatewayPage=="")
    {
      return (this.state.isData) ? (
        <div>
         <div className="passenger-details-area pt-5">
     <div className="container">
       <div className="row">
         <div className="col-lg-9 col-md-9">
           <h3>Payment Method</h3>
           <div className="payment-tab">
             <div className="timer-tab">
               <div className="row align-items-center">
                 <div className="col-lg-4 col-md-4">
                   <div className="timer">
                     <p className="small-text no-margin">
                       <svg
                         xmlns="http://www.w3.org/2000/svg"
                         width={16}
                         height={16}
                         fill="currentColor"
                         className="bi bi-clock mr-10"
                         viewBox="0 0 16 16"
                       >
                         <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                         <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                       </svg>{" "}
                       <span id="time">{minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}</span> minutes left to complete the booking
                     </p>
                   </div>
                 </div>
                 <div className="col-lg-8 col-md-8 amountPay"> 
                   <h4 className="no-margin">Amount to Pay : ₹{Math.round(this.state.ReceivedFromPaymentGet.paidAmount)}</h4>
                 </div>
               </div>
             </div>
             <div className="bg-white nav-border payment-tab">
               <div className="d-flex align-items-start">
                 <div
                   className="nav flex-column nav-pills me-3 nav-shadow pb-5"
                   id="v-pills-tab"
                   role="tablist"
                   aria-orientation="vertical"
                 >
                   <button
                     className="nav-link active"
                     onClick={() => this.handlePaymentTypeChange('UPI')}
                     id="v-pills-home-tab"
                     data-bs-toggle="pill"
                     data-bs-target="#v-pills-home"
                     type="button"
                     role="tab"
                     aria-controls="v-pills-home"
                     aria-selected="true"
                   >
                     <div className="circle-icon d-flex align-items-center justify-content-center me-3">
                       {" "}
                       <img src="assets/img/upi.svg" width={30} alt="" />{" "}
                     </div>
                     UPI
                   </button>
                   <button
                     className="nav-link"
                     onClick={() => this.handlePaymentTypeChange('CC')}
                     id="v-pills-profile-tab"
                     data-bs-toggle="pill"
                     data-bs-target="#v-pills-profile"
                     type="button"
                     role="tab"
                     aria-controls="v-pills-profile"
                     aria-selected="false"
                   >
                     <div className="circle-icon d-flex align-items-center justify-content-center me-3">
                       {" "}
                       <img src="assets/img/card.svg" width={30} alt="" />{" "}
                     </div>
                     Credit Card
                   </button>
                   <button
                     className="nav-link"
                     onClick={() => this.handlePaymentTypeChange('DC')}
                     id="v-pills-messages-tab"
                     data-bs-toggle="pill"
                     data-bs-target="#v-pills-messages"
                     type="button"
                     role="tab"
                     aria-controls="v-pills-messages"
                     aria-selected="false"
                   >
                     <div className="circle-icon d-flex align-items-center justify-content-center me-3">
                       {" "}
                       <img src="assets/img/card.svg" width={30} alt="" />{" "}
                     </div>
                     Debit Card
                   </button>
                   <button
                     className="nav-link"
                     onClick={() => this.handlePaymentTypeChange('NB')}
                     id="v-pills-settings-tab"
                     data-bs-toggle="pill"
                     data-bs-target="#v-pills-settings"
                     type="button"
                     role="tab"
                     aria-controls="v-pills-settings"
                     aria-selected="false"
                   >
                     <div className="circle-icon d-flex align-items-center justify-content-center me-3">
                       {" "}
                       <img src="assets/img/bank.svg" width={30} alt="" />{" "}
                     </div>
                     Net Banking
                   </button>
                 </div>
                 <div className="tab-content" id="v-pills-tabContent">
                   <div
                     className="tab-pane fade show active pt-4 me-4"
                     id="v-pills-home"
                     role="tabpanel"
                     aria-labelledby="v-pills-home-tab"
                   >
                     <div className="d-flex align-items-center mb-3">
                       <div>
                         <h4>Pay with UPI</h4>
                         <div className="mb-3">
                           <img
                             src="assets/img/upi-payment.png"
                             alt=""
                             width={250}
                             style={{ width: "200px !important" }}
                           />
                         </div>
                         <label htmlFor="" className="small-text">
                           Enter Virtual payment Address
                         </label>
                         <input
                           type="text"
                           className="form-control mb-3"
                           placeholder="username@bankname"
                           name='upiID'
                           onChange={(e)=>{this.OnChangeFieldData(e)}}
                         />
                         { (!this.state.isUpiValid) && <div class="error-message drop-in"><i class="material-icons">&#xe002;</i> Valid UPI ID Required </div>}
                         <div className="d-flex flex-row align-items-center mb-3">
                           <div className="mr-20">
                             <h3 className="no-margin">₹{Math.round(this.state.ReceivedFromPaymentGet.paidAmount)}</h3>
                           </div>
                           <div>
                             {/* <button onClick={this.submitPaymentRequest}>abc</button> */}
                             <button type="button" className="btn btn-primary btn-full" onClick={()=>this.submitPaymentRequest("UPI")}>
                               PAY NOW
                             </button>
                           </div>
                         </div>
                         <p className="small-text no-margin">
                         By clicking on the PAY button, I understand and agree to the Fare rules, the <Link to="/Privacy_Policy" target="_blank">Privacy Policy</Link>, and the<br /><Link to="/Terms_Conditions" target="_blank">Terms and Conditions</Link> of Travel Mythri.
                         </p>
                       </div>
                     </div>
                   </div>
                   <div
                     className="tab-pane fade pt-4 me-4"
                     id="v-pills-profile"
                     role="tabpanel"
                     aria-labelledby="v-pills-profile-tab"
                   >
                     <h4>Pay with Credit Card</h4>
                     <form className="form-horizontal">
                       <fieldset>
                         <div className="card-js form-group">
                           <div className="row">
                             <div className="col-lg-6 col-md-6">
                               <div className="card-wrapper">
                               <label>Card number</label>
                               <input
                                 className="card-number form-control"
                                 name="cardNumber"
                                 placeholder="Enter your card number"
                                 autoComplete="off"
                                 required=""
                                 onChange={(e)=>{this.OnChangeFieldData(e)}}
                                 onInput={(e) => {
                                  e.target.value = e.target.value.replace(/\D/g, '');
                                }}
                               />
                               { (!this.state.isCardNumber && this.state.PaymentType == "CC") && <div class="error-message drop-in"><i class="material-icons">&#xe002;</i> Valid Card No Required </div>}
                               <div className="icon"><svg
   version="1.1"
   id="Capa_1"
   xmlns="http://www.w3.org/2000/svg"
   xmlnsXlink="http://www.w3.org/1999/xlink"
   x="0px"
   y="3px"
   width="24px"
   height="17px"
   viewBox="0 0 216 146"
   enableBackground="new 0 0 216 146"
   xmlSpace="preserve"
 >
   <g>
     <path
       className="svg"
       d="M182.385,14.258c-2.553-2.553-5.621-3.829-9.205-3.829H42.821c-3.585,0-6.653,1.276-9.207,3.829c-2.553,2.553-3.829,5.621-3.829,9.206v99.071c0,3.585,1.276,6.654,3.829,9.207c2.554,2.553,5.622,3.829,9.207,3.829H173.18c3.584,0,6.652-1.276,9.205-3.829s3.83-5.622,3.83-9.207V23.464C186.215,19.879,184.938,16.811,182.385,14.258z M175.785,122.536c0,0.707-0.258,1.317-0.773,1.834c-0.516,0.515-1.127,0.772-1.832,0.772H42.821c-0.706,0-1.317-0.258-1.833-0.773c-0.516-0.518-0.774-1.127-0.774-1.834V73h135.571V122.536z M175.785,41.713H40.214v-18.25c0-0.706,0.257-1.316,0.774-1.833c0.516-0.515,1.127-0.773,1.833-0.773H173.18c0.705,0,1.316,0.257,1.832,0.773c0.516,0.517,0.773,1.127,0.773,1.833V41.713z"
     />
     <rect
       className="svg"
       x="50.643"
       y="104.285"
       width="20.857"
       height="10.429"
     />
     <rect
       className="svg"
       x="81.929"
       y="104.285"
       width="31.286"
       height="10.429"
     />
   </g>
 </svg>
 </div>
                               </div>
                             </div>
                             <div className="col-lg-6 col-md-6 mb-3">
                               <div className="card-wrapper">
                             <label>Name on your card</label>
                               <input
                                 className="name form-control"
                                 name="payerName"
                                 placeholder="Enter the name on your card"
                                 autoComplete="off"
                                 required=""
                                 onChange={(e)=>{this.OnChangeFieldData(e)}}
                                 onInput={(e) => {
                                  e.target.value = e.target.value.replace(/[0-9]/g, '');
                                }}
                               />
                               { (!this.state.isCardHolderName && this.state.PaymentType == "CC") && <div class="error-message drop-in"><i class="material-icons">&#xe002;</i> Valid Card Holder Name Required </div>}
                               <div className="icon">
                               <svg
   version="1.1"
   id="Capa_1"
   xmlns="http://www.w3.org/2000/svg"
   xmlnsXlink="http://www.w3.org/1999/xlink"
   x="0px"
   y="4px"
   width="24px"
   height="16px"
   viewBox="0 0 216 146"
   enableBackground="new 0 0 216 146"
   xmlSpace="preserve"
 >
   <g>
     <path
       className="svg"
       d="M107.999,73c8.638,0,16.011-3.056,22.12-9.166c6.111-6.11,9.166-13.483,9.166-22.12c0-8.636-3.055-16.009-9.166-22.12c-6.11-6.11-13.484-9.165-22.12-9.165c-8.636,0-16.01,3.055-22.12,9.165c-6.111,6.111-9.166,13.484-9.166,22.12c0,8.637,3.055,16.01,9.166,22.12C91.99,69.944,99.363,73,107.999,73z"
     />
     <path
       className="svg"
       d="M165.07,106.037c-0.191-2.743-0.571-5.703-1.141-8.881c-0.57-3.178-1.291-6.124-2.16-8.84c-0.869-2.715-2.037-5.363-3.504-7.943c-1.466-2.58-3.15-4.78-5.052-6.6s-4.223-3.272-6.965-4.358c-2.744-1.086-5.772-1.63-9.085-1.63c-0.489,0-1.63,0.584-3.422,1.752s-3.815,2.472-6.069,3.911c-2.254,1.438-5.188,2.743-8.799,3.909c-3.612,1.168-7.237,1.752-10.877,1.752c-3.639,0-7.264-0.584-10.876-1.752c-3.611-1.166-6.545-2.471-8.799-3.909c-2.254-1.439-4.277-2.743-6.069-3.911c-1.793-1.168-2.933-1.752-3.422-1.752c-3.313,0-6.341,0.544-9.084,1.63s-5.065,2.539-6.966,4.358c-1.901,1.82-3.585,4.02-5.051,6.6s-2.634,5.229-3.503,7.943c-0.869,2.716-1.589,5.662-2.159,8.84c-0.571,3.178-0.951,6.137-1.141,8.881c-0.19,2.744-0.285,5.554-0.285,8.433c0,6.517,1.983,11.664,5.948,15.439c3.965,3.774,9.234,5.661,15.806,5.661h71.208c6.572,0,11.84-1.887,15.806-5.661c3.966-3.775,5.948-8.921,5.948-15.439C165.357,111.591,165.262,108.78,165.07,106.037z"
     />
   </g>
 </svg>
 
                               </div>
                               </div>
                             </div>
                             <div className="col-lg-6 col-md-6">
                               <div className="card-wrapper">
                             <label>Date of Expiry</label>
                             <DatePicker name="cardExpiry" showMonthYearPicker value={this.state.cardExpiry} onChange={(date) => this.selectCardExpiry(date)} />
                               <div className="icon">
                               <svg
   version="1.1"
   id="Capa_1"
   xmlns="http://www.w3.org/2000/svg"
   xmlnsXlink="http://www.w3.org/1999/xlink"
   x="0px"
   y="4px"
   width="24px"
   height="16px"
   viewBox="0 0 216 146"
   enableBackground="new 0 0 216 146"
   xmlSpace="preserve"
 >
   <path
     className="svg"
     d="M172.691,23.953c-2.062-2.064-4.508-3.096-7.332-3.096h-10.428v-7.822c0-3.584-1.277-6.653-3.83-9.206c-2.554-2.553-5.621-3.83-9.207-3.83h-5.213c-3.586,0-6.654,1.277-9.207,3.83c-2.554,2.553-3.83,5.622-3.83,9.206v7.822H92.359v-7.822c0-3.584-1.277-6.653-3.83-9.206c-2.553-2.553-5.622-3.83-9.207-3.83h-5.214c-3.585,0-6.654,1.277-9.207,3.83c-2.553,2.553-3.83,5.622-3.83,9.206v7.822H50.643c-2.825,0-5.269,1.032-7.333,3.096s-3.096,4.509-3.096,7.333v104.287c0,2.823,1.032,5.267,3.096,7.332c2.064,2.064,4.508,3.096,7.333,3.096h114.714c2.824,0,5.27-1.032,7.332-3.096c2.064-2.064,3.096-4.509,3.096-7.332V31.286C175.785,28.461,174.754,26.017,172.691,23.953z M134.073,13.036c0-0.761,0.243-1.386,0.731-1.874c0.488-0.488,1.113-0.733,1.875-0.733h5.213c0.762,0,1.385,0.244,1.875,0.733c0.488,0.489,0.732,1.114,0.732,1.874V36.5c0,0.761-0.244,1.385-0.732,1.874c-0.49,0.488-1.113,0.733-1.875,0.733h-5.213c-0.762,0-1.387-0.244-1.875-0.733s-0.731-1.113-0.731-1.874V13.036z M71.501,13.036c0-0.761,0.244-1.386,0.733-1.874c0.489-0.488,1.113-0.733,1.874-0.733h5.214c0.761,0,1.386,0.244,1.874,0.733c0.488,0.489,0.733,1.114,0.733,1.874V36.5c0,0.761-0.244,1.386-0.733,1.874c-0.489,0.488-1.113,0.733-1.874,0.733h-5.214c-0.761,0-1.386-0.244-1.874-0.733c-0.488-0.489-0.733-1.113-0.733-1.874V13.036z M165.357,135.572H50.643V52.143h114.714V135.572z"
   />
 </svg>
                               </div>
                               { (!this.state.isCarDOE && this.state.PaymentType == "CC") && <div class="error-message drop-in"><i class="material-icons">&#xe002;</i> Valid Card DOE Required </div>}
                               </div>
                             </div>
                             <div className="col-lg-6 col-md-6">
                              <div className="card-wrapper">
                             <label>CVV</label>
                               <input
                                 className="name form-control"
                                 name="CVV"
                                 placeholder="CVV"
                                 autoComplete="off"
                                 required=""
                                 onChange={(e)=>{this.OnChangeFieldData(e)}}
                                 onInput={(e) => {
                                  e.target.value = e.target.value.replace(/\D/g, '');
                                }}
                               />
                               { (!this.state.isCardCVV && this.state.PaymentType == "CC") && <div class="error-message drop-in"><i class="material-icons">&#xe002;</i> Valid Card CVV Required </div>}
                               <div className="icon">
                               <svg
   version="1.1"
   id="Capa_1"
   xmlns="http://www.w3.org/2000/svg"
   xmlnsXlink="http://www.w3.org/1999/xlink"
   x="0px"
   y="3px"
   width="24px"
   height="17px"
   viewBox="0 0 216 146"
   enableBackground="new 0 0 216 146"
   xmlSpace="preserve"
 >
   <path
     className="svg"
     d="M152.646,70.067c-1.521-1.521-3.367-2.281-5.541-2.281H144.5V52.142c0-9.994-3.585-18.575-10.754-25.745c-7.17-7.17-15.751-10.755-25.746-10.755s-18.577,3.585-25.746,10.755C75.084,33.567,71.5,42.148,71.5,52.142v15.644h-2.607c-2.172,0-4.019,0.76-5.54,2.281c-1.521,1.52-2.281,3.367-2.281,5.541v46.929c0,2.172,0.76,4.019,2.281,5.54c1.521,1.52,3.368,2.281,5.54,2.281h78.214c2.174,0,4.02-0.76,5.541-2.281c1.52-1.521,2.281-3.368,2.281-5.54V75.607C154.93,73.435,154.168,71.588,152.646,70.067z M128.857,67.786H87.143V52.142c0-5.757,2.037-10.673,6.111-14.746c4.074-4.074,8.989-6.11,14.747-6.11s10.673,2.036,14.746,6.11c4.073,4.073,6.11,8.989,6.11,14.746V67.786z"
   />
 </svg>
 
                               </div>
                               </div>
                             </div>
 
 
 
                           </div>
                          
                          
                          
                        
                         </div>
                         <div className="d-flex flex-row align-items-center mb-3 pt-3">
                           <div className="mr-20">
                             <h3 className="no-margin">₹{Math.round(this.state.ReceivedFromPaymentGet.paidAmount)}</h3>
                           </div>
                           <div>
                             <button type="button" className="btn btn-primary btn-full" onClick={()=>this.submitPaymentRequest("CC")}>
                               PAY NOW
                             </button>
                           </div>
                         </div>
                         <p className="small-text no-margin">
                         By clicking on the PAY button, I understand and agree to the Fare rules, the <Link to="/Privacy_Policy" target="_blank">Privacy Policy</Link>, and the<br /><Link to="/Terms_Conditions" target="_blank">Terms and Conditions</Link> of Travel Mythri.
                         </p>
                       </fieldset>
                     </form>
                   </div>
                   <div
                     className="tab-pane fade pt-4 me-4"
                     id="v-pills-messages"
                     role="tabpanel"
                     aria-labelledby="v-pills-messages-tab"
                   >
                     <h4>Pay with Debit Card</h4>
                     <form className="form-horizontal">
                       <fieldset>
                         <div className="card-js form-group">
                           <div className="row">
                             <div className="col-lg-6 col-md-6">
                               <div className="card-wrapper">
                               <label>Card number</label>
                               <input
                                 className="card-number form-control"
                                 name="cardNumber"
                                 placeholder="Enter your card number"
                                 autoComplete="off"
                                 required=""
                                 onChange={(e)=>{this.OnChangeFieldData(e)}}
                                 onInput={(e) => {
                                  e.target.value = e.target.value.replace(/\D/g, '');
                                }}
                               />
                               { (!this.state.isCardNumber && this.state.PaymentType == "DC") && <div class="error-message drop-in"><i class="material-icons">&#xe002;</i> Valid Card No Required </div>}
                               <div className="icon"><svg
   version="1.1"
   id="Capa_1"
   xmlns="http://www.w3.org/2000/svg"
   xmlnsXlink="http://www.w3.org/1999/xlink"
   x="0px"
   y="3px"
   width="24px"
   height="17px"
   viewBox="0 0 216 146"
   enableBackground="new 0 0 216 146"
   xmlSpace="preserve"
 >
   <g>
     <path
       className="svg"
       d="M182.385,14.258c-2.553-2.553-5.621-3.829-9.205-3.829H42.821c-3.585,0-6.653,1.276-9.207,3.829c-2.553,2.553-3.829,5.621-3.829,9.206v99.071c0,3.585,1.276,6.654,3.829,9.207c2.554,2.553,5.622,3.829,9.207,3.829H173.18c3.584,0,6.652-1.276,9.205-3.829s3.83-5.622,3.83-9.207V23.464C186.215,19.879,184.938,16.811,182.385,14.258z M175.785,122.536c0,0.707-0.258,1.317-0.773,1.834c-0.516,0.515-1.127,0.772-1.832,0.772H42.821c-0.706,0-1.317-0.258-1.833-0.773c-0.516-0.518-0.774-1.127-0.774-1.834V73h135.571V122.536z M175.785,41.713H40.214v-18.25c0-0.706,0.257-1.316,0.774-1.833c0.516-0.515,1.127-0.773,1.833-0.773H173.18c0.705,0,1.316,0.257,1.832,0.773c0.516,0.517,0.773,1.127,0.773,1.833V41.713z"
     />
     <rect
       className="svg"
       x="50.643"
       y="104.285"
       width="20.857"
       height="10.429"
     />
     <rect
       className="svg"
       x="81.929"
       y="104.285"
       width="31.286"
       height="10.429"
     />
   </g>
 </svg>
 </div>
                               </div>
                             </div>
                             <div className="col-lg-6 col-md-6 mb-3">
                               <div className="card-wrapper">
                             <label>Name on your card</label>
                               <input
                                 className="name form-control"
                                 name="payerName"
                                 placeholder="Enter the name on your card"
                                 autoComplete="off"
                                 required=""
                                 onChange={(e)=>{this.OnChangeFieldData(e)}}
                                 onInput={(e) => {
                                  e.target.value = e.target.value.replace(/[0-9]/g, '');
                                }}
                               />
                               { (!this.state.isCardHolderName && this.state.PaymentType == "DC") && <div class="error-message drop-in"><i class="material-icons">&#xe002;</i> Valid Card Holder Name Required </div>}
                               <div className="icon">
                               <svg
   version="1.1"
   id="Capa_1"
   xmlns="http://www.w3.org/2000/svg"
   xmlnsXlink="http://www.w3.org/1999/xlink"
   x="0px"
   y="4px"
   width="24px"
   height="16px"
   viewBox="0 0 216 146"
   enableBackground="new 0 0 216 146"
   xmlSpace="preserve"
 >
   <g>
     <path
       className="svg"
       d="M107.999,73c8.638,0,16.011-3.056,22.12-9.166c6.111-6.11,9.166-13.483,9.166-22.12c0-8.636-3.055-16.009-9.166-22.12c-6.11-6.11-13.484-9.165-22.12-9.165c-8.636,0-16.01,3.055-22.12,9.165c-6.111,6.111-9.166,13.484-9.166,22.12c0,8.637,3.055,16.01,9.166,22.12C91.99,69.944,99.363,73,107.999,73z"
     />
     <path
       className="svg"
       d="M165.07,106.037c-0.191-2.743-0.571-5.703-1.141-8.881c-0.57-3.178-1.291-6.124-2.16-8.84c-0.869-2.715-2.037-5.363-3.504-7.943c-1.466-2.58-3.15-4.78-5.052-6.6s-4.223-3.272-6.965-4.358c-2.744-1.086-5.772-1.63-9.085-1.63c-0.489,0-1.63,0.584-3.422,1.752s-3.815,2.472-6.069,3.911c-2.254,1.438-5.188,2.743-8.799,3.909c-3.612,1.168-7.237,1.752-10.877,1.752c-3.639,0-7.264-0.584-10.876-1.752c-3.611-1.166-6.545-2.471-8.799-3.909c-2.254-1.439-4.277-2.743-6.069-3.911c-1.793-1.168-2.933-1.752-3.422-1.752c-3.313,0-6.341,0.544-9.084,1.63s-5.065,2.539-6.966,4.358c-1.901,1.82-3.585,4.02-5.051,6.6s-2.634,5.229-3.503,7.943c-0.869,2.716-1.589,5.662-2.159,8.84c-0.571,3.178-0.951,6.137-1.141,8.881c-0.19,2.744-0.285,5.554-0.285,8.433c0,6.517,1.983,11.664,5.948,15.439c3.965,3.774,9.234,5.661,15.806,5.661h71.208c6.572,0,11.84-1.887,15.806-5.661c3.966-3.775,5.948-8.921,5.948-15.439C165.357,111.591,165.262,108.78,165.07,106.037z"
     />
   </g>
 </svg>
 
                               </div>
                               </div>
                             </div>
                             <div className="col-lg-6 col-md-6">
                               <div className="card-wrapper">
                             <label>Date of Expiry</label>
                             <DatePicker name="cardExpiry" showMonthYearPicker value={this.state.cardExpiry} onChange={(date) => this.selectCardExpiry(date)} />
                               <div className="icon">
                               <svg
   version="1.1"
   id="Capa_1"
   xmlns="http://www.w3.org/2000/svg"
   xmlnsXlink="http://www.w3.org/1999/xlink"
   x="0px"
   y="4px"
   width="24px"
   height="16px"
   viewBox="0 0 216 146"
   enableBackground="new 0 0 216 146"
   xmlSpace="preserve"
 >
   <path
     className="svg"
     d="M172.691,23.953c-2.062-2.064-4.508-3.096-7.332-3.096h-10.428v-7.822c0-3.584-1.277-6.653-3.83-9.206c-2.554-2.553-5.621-3.83-9.207-3.83h-5.213c-3.586,0-6.654,1.277-9.207,3.83c-2.554,2.553-3.83,5.622-3.83,9.206v7.822H92.359v-7.822c0-3.584-1.277-6.653-3.83-9.206c-2.553-2.553-5.622-3.83-9.207-3.83h-5.214c-3.585,0-6.654,1.277-9.207,3.83c-2.553,2.553-3.83,5.622-3.83,9.206v7.822H50.643c-2.825,0-5.269,1.032-7.333,3.096s-3.096,4.509-3.096,7.333v104.287c0,2.823,1.032,5.267,3.096,7.332c2.064,2.064,4.508,3.096,7.333,3.096h114.714c2.824,0,5.27-1.032,7.332-3.096c2.064-2.064,3.096-4.509,3.096-7.332V31.286C175.785,28.461,174.754,26.017,172.691,23.953z M134.073,13.036c0-0.761,0.243-1.386,0.731-1.874c0.488-0.488,1.113-0.733,1.875-0.733h5.213c0.762,0,1.385,0.244,1.875,0.733c0.488,0.489,0.732,1.114,0.732,1.874V36.5c0,0.761-0.244,1.385-0.732,1.874c-0.49,0.488-1.113,0.733-1.875,0.733h-5.213c-0.762,0-1.387-0.244-1.875-0.733s-0.731-1.113-0.731-1.874V13.036z M71.501,13.036c0-0.761,0.244-1.386,0.733-1.874c0.489-0.488,1.113-0.733,1.874-0.733h5.214c0.761,0,1.386,0.244,1.874,0.733c0.488,0.489,0.733,1.114,0.733,1.874V36.5c0,0.761-0.244,1.386-0.733,1.874c-0.489,0.488-1.113,0.733-1.874,0.733h-5.214c-0.761,0-1.386-0.244-1.874-0.733c-0.488-0.489-0.733-1.113-0.733-1.874V13.036z M165.357,135.572H50.643V52.143h114.714V135.572z"
   />
 </svg>
                               </div>
                               { (!this.state.isCarDOE && this.state.PaymentType == "DC") && <div class="error-message drop-in"><i class="material-icons">&#xe002;</i> Valid Card DOE Required </div>}
                               </div>
                             </div>
                             <div className="col-lg-6 col-md-6">
                              <div className="card-wrapper">
                             <label>CVV</label>
                               <input
                                 className="name form-control"
                                 name="CVV"
                                 placeholder="CVV"
                                 autoComplete="off"
                                 required=""
                                 onChange={(e)=>{this.OnChangeFieldData(e)}}
                                 onInput={(e) => {
                                  e.target.value = e.target.value.replace(/\D/g, '');
                                }}
                               />
                               { (!this.state.isCardCVV && this.state.PaymentType == "DC") && <div class="error-message drop-in"><i class="material-icons">&#xe002;</i> Valid Card CVV Required </div>}
                               <div className="icon">
                               <svg
   version="1.1"
   id="Capa_1"
   xmlns="http://www.w3.org/2000/svg"
   xmlnsXlink="http://www.w3.org/1999/xlink"
   x="0px"
   y="3px"
   width="24px"
   height="17px"
   viewBox="0 0 216 146"
   enableBackground="new 0 0 216 146"
   xmlSpace="preserve"
 >
   <path
     className="svg"
     d="M152.646,70.067c-1.521-1.521-3.367-2.281-5.541-2.281H144.5V52.142c0-9.994-3.585-18.575-10.754-25.745c-7.17-7.17-15.751-10.755-25.746-10.755s-18.577,3.585-25.746,10.755C75.084,33.567,71.5,42.148,71.5,52.142v15.644h-2.607c-2.172,0-4.019,0.76-5.54,2.281c-1.521,1.52-2.281,3.367-2.281,5.541v46.929c0,2.172,0.76,4.019,2.281,5.54c1.521,1.52,3.368,2.281,5.54,2.281h78.214c2.174,0,4.02-0.76,5.541-2.281c1.52-1.521,2.281-3.368,2.281-5.54V75.607C154.93,73.435,154.168,71.588,152.646,70.067z M128.857,67.786H87.143V52.142c0-5.757,2.037-10.673,6.111-14.746c4.074-4.074,8.989-6.11,14.747-6.11s10.673,2.036,14.746,6.11c4.073,4.073,6.11,8.989,6.11,14.746V67.786z"
   />
 </svg>
 
                               </div>
                               </div>
                             </div>
 
 
 
                           </div>
                          
                         </div>
                         <div className="d-flex flex-row align-items-center mb-3 pt-3">
                           <div className="mr-20">
                             <h3 className="no-margin">₹{Math.round(this.state.ReceivedFromPaymentGet.paidAmount)}</h3>
                           </div>
                           <div>
                             <button type="button" className="btn btn-primary btn-full" onClick={()=>this.submitPaymentRequest("DC")} >
                               PAY NOW
                             </button>
                           </div>
                         </div>
                         <p className="small-text no-margin">
                         By clicking on the PAY button, I understand and agree to the Fare rules, the <Link to="/Privacy_Policy" target="_blank">Privacy Policy</Link>, and the<br /><Link to="/Terms_Conditions" target="_blank">Terms and Conditions</Link> of Travel Mythri.
                         </p>
                       </fieldset>
                     </form>
                   </div>
                   <div
                     className="tab-pane fade pt-4 me-4"
                     id="v-pills-settings"
                     role="tabpanel"
                     aria-labelledby="v-pills-settings-tab"
                   >
                     <h4 className="mb-3">Pay with Net Banking</h4>
                     <div className="row">
                     {this.state.BankCodes.filter(bank => bank.status === true).map((bank, index) => (
                      <div className="col-lg-2 col-md-2" key={index}>
                        <input 
                          type="radio" 
                          id={`bank${index}`} 
                          name="selectedBank" 
                          value={bank.bankCode} 
                          checked={this.state.selectedBank === bank.bankCode} 
                          onChange={() => {
                            this.setState({ selectedBank: bank.bankCode });
                            document.getElementById('bankDropdown').value = '';
                        }}
                        />
                        <label htmlFor={`bank${index}`}>
                          <img
                            src={`assets/BankCodes/${bank.bankCode}.png`}
                            width={40}
                            className="bank-label"
                            alt={bank.bankName}
                          />
                        </label>
                      </div>
                    ))}
                      <div className="col-lg-12 col-md-12 mb-3">
                         <div className="row">
                          <div className="col-md-6 pt-3">
                          <label htmlFor=""><strong>Other Banks</strong></label>
                          <select 
                            name="" 
                            id="bankDropdown" 
                            className="form-control"
                            onChange={this.handleDropdownChange}
                          >
                            <option value="">Select your bank</option>
                            {this.state.BankCodes.filter(bank => bank.status === false).map((bank, index) => (
                              <option key={index} value={bank.bankCode}>{bank.bankName}</option>
                            ))}
                          </select>
                        </div>
                         </div>
                      
                         <div className="row pt-4">
                           <div className="d-flex flex-row align-items-center mb-3">
                             <div className="mr-20">
                               <h3 className="no-margin">₹{Math.round(this.state.ReceivedFromPaymentGet.paidAmount)}</h3>
                             </div>
                             <div>
                               <button type="button" className="btn btn-primary btn-full" onClick={()=>this.submitPaymentRequest("NB")}>
                                 PAY NOW
                               </button>
                             </div>
                           </div>
                           <p className="small-text no-margin">
                           By clicking on the PAY button, I understand and agree to the Fare rules, the <Link to="/Privacy_Policy" target="_blank">Privacy Policy</Link>, and the<br /><Link to="/Terms_Conditions" target="_blank">Terms and Conditions</Link> of Travel Mythri.
                         </p>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
                 <Modal show={this.state.showInfoModal} onHide={this.handleInfoClose} centered backdrop="static" keyboard={false}>
         
          <Modal.Body>
               {/* percentage starts */}
               {this.state.PaymentType != "UPI" && 
              <div className="text-center p-3">
                <p className="no-margin"><strong>The selected payment method (Net Banking/Credit Card/Debit Card) incurs additional charges.<br />For a more cost-effective option, we recommend using UPI</strong></p>
              </div>
            }

            {/* percentage area ends */}
          </Modal.Body>
          <Modal.Footer className="text-center justify-content-center">
            <Button variant="secondary" onClick={this.handleInfoClose}>
              Ok
            </Button>
           
          </Modal.Footer>
        </Modal>
               </div>
             </div>
           </div>
         </div>
         <div className="col-lg-3 col-md-3">
           <div className="mb-2">
             <h4 className="no-margin">Price Summary</h4>
           </div>
           <div className="price_area">
             <h4>₹{Math.round(this.state.ReceivedFromPaymentGet.paidAmount)}</h4>
           </div>
           <div className="t-details-area price-box mb-4">
             <div>
               <div className="bottom-border">
                 <span className="small-text">Retail Saver</span>
               </div>
               <div className="row">
               <div className="col-md-6 col-lg-6">
                 <span className="small-text">Adult {this.state.ReceivedFromPaymentGet.adult}</span>
               </div>
               <div className="col-md-6 col-lg-6 text-right">
                 <p
                   className="no-margin"
                   data-bs-toggle="collapse"
                   href="#priceCollapsead"
                   role="button"
                   aria-expanded="false"
                   aria-controls="priceCollapsead"
                 > 
                   ₹ {Math.round((this.state.ReceivedFromPaymentGet.adult * this.state.ReceivedFromPaymentGet.adultBasic) + (this.state.ReceivedFromPaymentGet.adult * this.state.ReceivedFromPaymentGet.adultTax))}{" "}
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width={10}
                     height={10}
                     fill="currentColor"
                     className="bi bi-chevron-down"
                     viewBox="0 0 16 16"
                   >
                     <path
                       fillRule="evenodd"
                       d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                     />
                   </svg>
                 </p>
               </div>
               </div>
               <div className="collapse" id="priceCollapsead">
                 <div className="card card-body">
                   <div className="row">
                     <div className="col-lg-6 col-md-6">
                       <p className="small-text no-margin">Base Fare</p>
                     </div>
                     <div className="col-lg-6 col-md-6">
                       <p className="small-text no-margin text-right">₹{Math.round(this.state.ReceivedFromPaymentGet.adult * this.state.ReceivedFromPaymentGet.adultBasic)}</p>
                     </div>
                     <div className="col-lg-6 col-md-6">
                       <p className="small-text no-margin">Tax</p>
                     </div>
                     <div className="col-lg-6 col-md-6"> 
                       <p className="small-text no-margin text-right">₹{Math.round(this.state.ReceivedFromPaymentGet.adult * this.state.ReceivedFromPaymentGet.adultTax)}</p>
                     </div>
                     {/* <div className="col-lg-6 col-md-6">
                       <p className="small-text no-margin">GST</p>
                     </div>
                     <div className="col-lg-6 col-md-6">
                       <p className="small-text no-margin text-right">₹{this.state.ReceivedFromPaymentGet.paidAmount}</p>
                     </div> */}
                   </div>
                 </div>
               </div>
 
 
               {   
                   (this.state.ReceivedFromPaymentGet.child > 0) &&   <div className="row">
                      <div className="col-md-6 col-lg-6">
                     <span className="small-text">Child {this.state.ReceivedFromPaymentGet.child}</span>
                      </div>
                      <div className="col-md-6 col-lg-6 text-right">
                     <p
                       className="no-margin"
                       data-bs-toggle="collapse"
                       href="#priceCollapsech"
                       role="button"
                       aria-expanded="false"
                       aria-controls="priceCollapsech"
                     >   
                       ₹ {Math.round((this.state.ReceivedFromPaymentGet.child * this.state.ReceivedFromPaymentGet.childBasic) + (this.state.ReceivedFromPaymentGet.child * this.state.ReceivedFromPaymentGet.childTax))}{" "}
                       <svg
                         xmlns="http://www.w3.org/2000/svg"
                         width={10}
                         height={10}
                         fill="currentColor"
                         className="bi bi-chevron-down"
                         viewBox="0 0 16 16"
                       >
                         <path
                           fillRule="evenodd"
                           d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                         />
                       </svg>
                     </p>
                      </div>
                      <div className="collapse" id="priceCollapsech">
                     <div className="card card-body">
                       <div className="row">
                         <div className="col-lg-6 col-md-6">
                           <p className="small-text no-margin">Base Fare</p>
                         </div>
                         <div className="col-lg-6 col-md-6">   
                           <p className="small-text no-margin text-right">₹{Math.round(this.state.ReceivedFromPaymentGet.child * this.state.ReceivedFromPaymentGet.childBasic)}</p>
                         </div>
                         <div className="col-lg-6 col-md-6">
                           <p className="small-text no-margin">Tax</p>
                         </div>
                         <div className="col-lg-6 col-md-6">   
                           <p className="small-text no-margin text-right">₹{Math.round(this.state.ReceivedFromPaymentGet.child * this.state.ReceivedFromPaymentGet.childTax)}</p>
                         </div>
                         {/* <div className="col-lg-6 col-md-6">
                           <p className="small-text no-margin">GST</p>
                         </div>
                         <div className="col-lg-6 col-md-6">
                           <p className="small-text no-margin text-right">₹{this.state.ReceivedFromPaymentGet.paidAmount}</p>
                         </div> */}
                       </div>
                     </div>
                      </div>
                     </div>
               }
 
 
 {   
                   (this.state.ReceivedFromPaymentGet.infant > 0) &&   <div className="row">
                   <div className="col-md-6 col-lg-6">
                     <span className="small-text">Infant {this.state.ReceivedFromPaymentGet.infant}</span>
                     </div>
                     <div className="col-md-6 col-lg-6 text-right">
                     <p
                       className="no-margin"
                       data-bs-toggle="collapse"
                       href="#priceCollapseinf"
                       role="button"
                       aria-expanded="false"
                       aria-controls="priceCollapseinf"
                     >  
                       ₹ {Math.round((this.state.ReceivedFromPaymentGet.infant * this.state.ReceivedFromPaymentGet.infantBasic) + (this.state.ReceivedFromPaymentGet.infant * this.state.ReceivedFromPaymentGet.infantTax))}{" "}
                       <svg
                         xmlns="http://www.w3.org/2000/svg"
                         width={10}
                         height={10}
                         fill="currentColor"
                         className="bi bi-chevron-down"
                         viewBox="0 0 16 16"
                       >
                         <path
                           fillRule="evenodd"
                           d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                         />
                       </svg>
                     </p>
                     </div>
                     <div className="collapse" id="priceCollapseinf">
                     <div className="card card-body">
                       <div className="row">
                         <div className="col-lg-6 col-md-6">
                           <p className="small-text no-margin">Base Fare</p>
                         </div>
                         <div className="col-lg-6 col-md-6">   
                           <p className="small-text no-margin text-right">₹{Math.round(this.state.ReceivedFromPaymentGet.infant * this.state.ReceivedFromPaymentGet.infantBasic)}</p>
                         </div>
                         <div className="col-lg-6 col-md-6">
                           <p className="small-text no-margin">Tax</p>
                         </div>
                         <div className="col-lg-6 col-md-6">   
                           <p className="small-text no-margin text-right">₹{Math.round(this.state.ReceivedFromPaymentGet.infant * this.state.ReceivedFromPaymentGet.infantTax)}</p>
                         </div>
                         {/* <div className="col-lg-6 col-md-6">
                           <p className="small-text no-margin">GST</p>
                         </div>
                         <div className="col-lg-6 col-md-6">
                           <p className="small-text no-margin text-right">₹{this.state.ReceivedFromPaymentGet.paidAmount}</p>
                         </div> */}
                       </div>
                     </div>
                     </div>
                     </div>
               }
 
 <div className="row">
               <div className="col-md-6 col-lg-6">
                 <span className="no-margin">Convenience Fee</span>
               </div>
               <div className="col-md-6 col-lg-6 text-right">   
                 <span className="no-margin">₹ {Math.round(this.state.ReceivedFromPaymentGet.convenienceFee)}</span>
               </div>
               <div className="col-md-6 col-lg-6">
                 <span className="no-margin">Cost</span>
               </div>
               <div className="col-md-6 col-lg-6 text-right">   
                 <span className="no-margin">₹ {Math.round(this.state.ReceivedFromPaymentGet.totalBasic+this.state.ReceivedFromPaymentGet.totalTax+this.state.ReceivedFromPaymentGet.convenienceFee)}</span>
               </div>
               <div className="col-md-6 col-lg-6">
                 <span className="no-margin">Addons</span>
               </div>
               <div className="col-md-6 col-lg-6 text-right">    
                 <span className="no-margin">₹ {Math.round(this.state.ReceivedFromPaymentGet.ssrTotal)}</span>
               </div>
               <div className="col-md-6 col-lg-6">
                 <span className="no-margin">Insurance</span>
               </div>
               <div className="col-md-6 col-lg-6 text-right">    
                 <span className="no-margin">₹ {Math.round(this.state.ReceivedFromPaymentGet.insuranceTotal)}</span>
               </div>
               {/* <div className="col-md-6 col-lg-6">
                 <span className="no-margin">Discount</span>
               </div>
               <div className="col-md-6 col-lg-6 text-right">    
                 <span className="no-margin">₹ {Math.round(this.state.ReceivedFromPaymentGet.totalDiscount)}</span>
               </div> */}
               <div className="col-md-6 col-lg-6">
                 <span className="no-margin">Grand Total</span>
               </div>
               <div className="col-md-6 col-lg-6 text-right">    
                 <span className="no-margin">₹ {Math.round(this.state.ReceivedFromPaymentGet.paidAmount)}</span>
               </div>
               </div>
             </div>
           </div>

         
 
            {/* Special offer area starts */}
            {this.state.ReceivedFromPaymentGet && 
              (this.state.ReceivedFromPaymentGet.specialDiscount + this.state.ReceivedFromPaymentGet.totalDiscount >= 1) && (
              <div className="t-details-area position-relative sp-o-area">
                <div className="special-offer">
                  <p><svg fill="#ffffff" width="15px" height="15px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m20.749 12 1.104-1.908a1 1 0 0 0-.365-1.366l-1.91-1.104v-2.2a1 1 0 0 0-1-1h-2.199l-1.103-1.909a1.008 1.008 0 0 0-.607-.466.993.993 0 0 0-.759.1L12 3.251l-1.91-1.105a1 1 0 0 0-1.366.366L7.62 4.422H5.421a1 1 0 0 0-1 1v2.199l-1.91 1.104a.998.998 0 0 0-.365 1.367L3.25 12l-1.104 1.908a1.004 1.004 0 0 0 .364 1.367l1.91 1.104v2.199a1 1 0 0 0 1 1h2.2l1.104 1.91a1.01 1.01 0 0 0 .866.5c.174 0 .347-.046.501-.135l1.908-1.104 1.91 1.104a1.001 1.001 0 0 0 1.366-.365l1.103-1.91h2.199a1 1 0 0 0 1-1v-2.199l1.91-1.104a1 1 0 0 0 .365-1.367L20.749 12zM9.499 6.99a1.5 1.5 0 1 1-.001 3.001 1.5 1.5 0 0 1 .001-3.001zm.3 9.6-1.6-1.199 6-8 1.6 1.199-6 8zm4.7.4a1.5 1.5 0 1 1 .001-3.001 1.5 1.5 0 0 1-.001 3.001z"></path></g></svg> Discount!</p>
                </div>
                {/* <p>We have applied a special offer for you</p> */}
                <h4>₹{Math.round(this.state.ReceivedFromPaymentGet.specialDiscount + this.state.ReceivedFromPaymentGet.totalDiscount)}</h4>
              </div>
            )}

            {/* Special offer area ends */}

           {(this.state.ReceivedFromPaymentGet.onwardDetails != null) &&
             <div className="t-details-area price-box mb-2 f-details-area">            
             <h6 className="no-margin font-500 fd">
             { this.state.ReceivedFromPaymentGet.onwardDetails.departure }{" "}
               <span>
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   width={16}
                   height={16}
                   fill="currentColor"
                   className="bi bi-arrow-right-short"
                   viewBox="0 0 16 16"
                 >
                   <path
                     fillRule="evenodd"
                     d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                   />
                 </svg>
               </span>{" "}
               { this.state.ReceivedFromPaymentGet.onwardDetails.arrival}
             </h6>
             <p className="small-text no-margin text-grey">
             { this.state.ReceivedFromPaymentGet.onwardDetails.departureDate} . { this.state.ReceivedFromPaymentGet.onwardDetails.paxCount} Traveller
             </p>
             <hr />
             <div className="d-flex flex-row">
               <div className="mr-10">
                 <img
                   src={`assets//img/logos/${this.state.ReceivedFromPaymentGet.onwardDetails.airlineCode}.gif`}
                   width={40}
                 />
               </div>
               <div className="mr-10">
                 <h6 className="no-margin font-500 fd"> { this.state.ReceivedFromPaymentGet.onwardDetails.departure } { this.state.ReceivedFromPaymentGet.onwardDetails.departureTime }</h6>
                 <p className="no-margin small-text text-grey" style={{fontSize: '11px'}}>{ this.state.ReceivedFromPaymentGet.onwardDetails.departureDate }</p>
               </div>
               <div className="text-center mr-20">
                 <span>
                   <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width={25}
                     height={25}
                     fill="currentColor"
                     className="bi bi-arrow-right-short"
                     viewBox="0 0 16 16"
                   >
                     <path
                       fillRule="evenodd"
                       d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                     />
                   </svg>
                 </span>
                 <p className="small-text text-grey" style={{fontSize: '11px'}}> { this.state.ReceivedFromPaymentGet.onwardDetails.travelDuration}</p>
               </div>
               <div>
                 <h6 className="no-margin font-500 fd"> { this.state.ReceivedFromPaymentGet.onwardDetails.arrival } { this.state.ReceivedFromPaymentGet.onwardDetails.arrivalTime }</h6>
                 <p className="no-margin small-text text-grey" style={{fontSize: '11px'}}>{ this.state.ReceivedFromPaymentGet.onwardDetails.arrivalDate }</p>
               </div>
             </div>
           </div>
           }
 
 
             {(this.state.ReceivedFromPaymentGet.returnDetails != null) &&
                         <div className="t-details-area price-box mb-2 f-details-area">            
                         <h6 className="no-margin font-500 fd">
                         { this.state.ReceivedFromPaymentGet.returnDetails.departure }{" "}
                           <span>
                             <svg
                               xmlns="http://www.w3.org/2000/svg"
                               width={16}
                               height={16}
                               fill="currentColor"
                               className="bi bi-arrow-right-short"
                               viewBox="0 0 16 16"
                             >
                               <path
                                 fillRule="evenodd"
                                 d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                               />
                             </svg>
                           </span>{" "}
                           { this.state.ReceivedFromPaymentGet.returnDetails.arrival}
                         </h6>
                         <p className="small-text no-margin text-grey" style={{fontSize: '11px'}}>
                         { this.state.ReceivedFromPaymentGet.returnDetails.departureDate} . { this.state.ReceivedFromPaymentGet.returnDetails.paxCount} Traveller
                         </p>
                         <hr />
                         <div className="d-flex flex-row">
                           <div className="mr-10">
                             <img
                               src={`assets//img/logos/${this.state.ReceivedFromPaymentGet.returnDetails.airlineCode}.gif`}
                               width={40}
                             />
                           </div>
                           <div className="mr-10">
                             <h6 className="no-margin font-500 fd"> { this.state.ReceivedFromPaymentGet.returnDetails.departure } { this.state.ReceivedFromPaymentGet.returnDetails.departureTime }</h6>
                             <p className="no-margin small-text text-grey" style={{fontSize: '11px'}}>{ this.state.ReceivedFromPaymentGet.returnDetails.departureDate }</p>
                           </div>
                           <div className="text-center mr-20">
                             <span>
                               <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width={25}
                                 height={25}
                                 fill="currentColor"
                                 className="bi bi-arrow-right-short"
                                 viewBox="0 0 16 16"
                               >
                                 <path
                                   fillRule="evenodd"
                                   d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                                 />
                               </svg>
                             </span>
                             <p className="small-text text-grey" style={{fontSize: '11px'}}> { this.state.ReceivedFromPaymentGet.returnDetails.travelDuration}</p>
                           </div>
                           <div>
                             <h6 className="no-margin font-500"> { this.state.ReceivedFromPaymentGet.returnDetails.arrival } { this.state.ReceivedFromPaymentGet.returnDetails.arrivalTime }</h6>
                             <p className="no-margin small-text text-grey" style={{fontSize: '11px'}}>{ this.state.ReceivedFromPaymentGet.returnDetails.arrivalDate }</p>
                           </div>
                         </div>
                       </div>
                       }
           
         </div>
       </div>
     </div>
   </div>
   
       </div>
   
     ):(
       <div></div>
     )
    }
    else
    {
      // const data = parse(this.state.paymentGatewayPage);
      // const htmlCode = DOMPurify.sanitize(this.state.paymentGatewayPage);
      return (
       <div>
            {/* <div class="loadingpay">Loading</div> */}
            <div className="loader text-center">
 <div className="wait"> 
 <div className="text-center">
  <img src="assets/img/loading-gif.gif" width={60} />
 </div>
    <h4>Please wait!</h4>
    <p>Please Don't press back button and Refresh the page!</p>
    
  </div> 


 

</div>


           {/* <div dangerouslySetInnerHTML={{ __html: this.state.paymentGatewayPage }} >
           </div> */}
           {/* {this.componentDidUpdate1()} */}
           {/* {!formSubmitted && this.componentDidUpdate1()} */}
       </div>
      )
    }

  }
}


function WithNavigate(props) {
  let navigate = useNavigate();
  return <Payment {...props} navigate={navigate}/>
}

export default WithNavigate;















